<main class="container">
  <header class="justify mt-3">
    <h1>{{uiData?.header}} {{selectedFlour?.flour}}</h1>
    <p class="text-justify">
      @if(!flourKey){
      {{uiData?.generalDescription}}
      }
      @else{
      {{selectedFlour?.description}}
      }
    </p>
  </header>

  <h2 class="large-text mt-5">{{uiData?.compare}} {{selectedFlour?.flour}}:</h2>
  <p-table [value]="flours || []" selectionMode="single" dataKey="name" tableStyleClass="w-full"
    styleClass="p-datatable-sm p-datatable-striped">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="flour">Flour <p-sortIcon field="flour" /></th>
        <th pSortableColumn="calories">Calories <p-sortIcon field="calories" /></th>
        <th pSortableColumn="fat">Fat <p-sortIcon field="fat" /></th>
        <th pSortableColumn="saturatedFat">SaturatedFat <p-sortIcon field="saturatedFat" /></th>
        <th pSortableColumn="carbs">Carbs <p-sortIcon field="carbs" /></th>
        <th pSortableColumn="sugar">Sugar <p-sortIcon field="sugar" /></th>
        <th pSortableColumn="fibre">Fibre <p-sortIcon field="fibre" /></th>
        <th pSortableColumn="protein">Protein <p-sortIcon field="protein" /></th>
        <th pSortableColumn="iron">Iron <p-sortIcon field="iron" /></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-flourItem>
      <tr>
        @if(flourItem?.flour === selectedFlour?.flour){
          <td class="text-xl font-semibold text-yellow-700">{{flourItem?.flour}} ({{flourItem?.quantity}}{{flourItem?.quantityUnit}})</td>
          <td class="text-xl font-semibold text-yellow-700">{{flourItem?.calories}} {{flourItem?.caloriesLabel}}</td>
          <td class="text-xl font-semibold text-yellow-700">{{flourItem?.fat}} {{flourItem?.fatLabel}}</td>
          <td class="text-xl font-semibold text-yellow-700">{{flourItem?.saturatedFat}} {{flourItem?.saturatedFatLabel}}</td>
          <td class="text-xl font-semibold text-yellow-700">{{flourItem?.carbs}} {{flourItem?.carbsLabel}}</td>
          <td class="text-xl font-semibold text-yellow-700">{{flourItem?.sugar}} {{flourItem?.sugarLabel}}</td>
          <td class="text-xl font-semibold text-yellow-700">{{flourItem?.fibre}} {{flourItem?.fibreLabel}}</td>
          <td class="text-xl font-semibold text-yellow-700">{{flourItem?.protein}} {{flourItem?.proteinLabel}}</td>
          <td class="text-xl font-semibold text-yellow-700">{{flourItem?.iron}} {{flourItem?.ironLabel}}</td>          
        }
        @else{
          <td>
            <a [href]="'/flour-comparer/' + flourItem?.key + '/'">
              <u>{{flourItem?.flour}} ({{flourItem?.quantity}}{{flourItem?.quantityUnit}})</u>
            </a>
          </td>
          <td nowrap>
            <i [class]="setComparerClass(flourItem?.calories, selectedFlour.calories)"></i>
            <span [class]="setTextComparerClass(flourItem?.calories, selectedFlour.calories)">&nbsp;{{flourItem?.calories}}
              {{flourItem?.caloriesLabel}}</span>
          </td>
          <td nowrap>
            <i [class]="setComparerClass(flourItem?.fat, selectedFlour.fat)"></i>
            <span [class]="setTextComparerClass(flourItem?.fat, selectedFlour.fat)">&nbsp;{{flourItem?.fat}}
              {{flourItem?.fatLabel}}</span>
          </td>
          <td nowrap>
            <i [class]="setComparerClass(flourItem?.saturatedFat, selectedFlour.saturatedFat)"></i>
            <span
              [class]="setTextComparerClass(flourItem?.saturatedFat, selectedFlour.saturatedFat)">&nbsp;{{flourItem?.saturatedFat}}
              {{flourItem?.saturatedFatLabel}}</span>
          </td>
          <td nowrap>
            <i [class]="setComparerClass(flourItem?.carbs, selectedFlour.carbs)"></i>
            <span [class]="setTextComparerClass(flourItem?.carbs, selectedFlour.carbs)">&nbsp;{{flourItem?.carbs}}
              {{flourItem?.carbsLabel}}</span>
          </td>
          <td nowrap>
            <i [class]="setComparerClass(flourItem?.sugar, selectedFlour.sugar)"></i>
            <span [class]="setTextComparerClass(flourItem?.sugar, selectedFlour.sugar)">&nbsp;{{flourItem?.sugar}}
              {{flourItem?.sugarLabel}}</span>
          </td>
          <td nowrap>
            <i [class]="setComparerClass(flourItem?.fibre, selectedFlour.fibre)"></i>
            <span [class]="setTextComparerClass(flourItem?.fibre, selectedFlour.fibre)">&nbsp;{{flourItem?.fibre}}
              {{flourItem?.fibreLabel}}</span>
          </td>
          <td nowrap>
            <i [class]="setComparerClass(flourItem?.protein, selectedFlour.protein)"></i>
            <span [class]="setTextComparerClass(flourItem?.protein, selectedFlour.protein)">&nbsp;{{flourItem?.protein}}
              {{flourItem?.proteinLabel}}</span>
          </td>
          <td nowrap>
            <i [class]="setComparerClass(flourItem?.iron, selectedFlour.iron)"></i>
            <span [class]="setTextComparerClass(flourItem?.iron, selectedFlour.iron)">&nbsp;{{flourItem?.iron}}
              {{flourItem?.ironLabel}}</span>
          </td>       
        }        
      </tr>
    </ng-template>
  </p-table>

  @if(recipesList?.length > 0){
    <section class="mt-3">
      <header>
        <h2>{{uiData?.recipesTablePrefix}} {{this.selectedFlour?.flour}} {{uiData?.recipesTableSuffix}}</h2>
      </header>
      <app-recipes-grid [recipes]="recipesList"></app-recipes-grid>
    </section>
  }
  
  <section class="mt-3">
    <header>
      <h2>{{selectedFlour?.featuresTitle}}:</h2>
    </header>
    <ul class="mt-3 list-decimal">
      @for (item of selectedFlour?.features; track $index) {
        <li>
          <h3>{{item?.title}}</h3>
          <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(item?.description || '')"></div>
        </li>
      }      
    </ul>
    <p class="mt-3 text-lg font-semibold text-justify">{{selectedFlour?.descriptionSummary}}</p>    
  </section>

</main>