import { Component, inject, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { JsonFilesDataService, RecipesService, UtilsService } from '../../services';
import { RecipeDataModel } from '../../models';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-video',
  standalone: true,
  imports: [BreadcrumbModule, TableModule],
  templateUrl: './video.component.html'
})
export class VideoComponent {
  jsonFilesDataService = inject(JsonFilesDataService);
  recipesService = inject(RecipesService);
  utilsService = inject(UtilsService);

  breadcrumbItems: MenuItem[] | undefined;
  uiData: any;
  recipe!: RecipeDataModel;
  @Input() groupKey!: string;
  @Input() groupSubKey!: string;
  @Input() recipeKey!: string;

  ngOnInit() {
    this.jsonFilesDataService.getRecipeDetailsUiData((uiData: any) => {
      this.uiData = uiData;
      this.recipesService.getRecipe(this.recipeKey, (data: RecipeDataModel) => {
        this.recipe = data;
        this.setBreadcrumbItems();

        this.utilsService.setPageSeoMeta({
          prefix: this.uiData.video, 
          title: this.recipe.meta.title, 
          description: this.recipe.meta.shortDescription,
          image: `${this.uiData.siteAddress}assets/recipes/${this.utilsService.language}/${this.recipeKey}/${this.recipe?.details?.titleImage}`
        });
      })
    });
  }  

  videoSource() {
    return `/assets/recipes/${this.utilsService.language}/${this.recipe?.meta?.recipeKey}/video/${this.recipe?.meta?.video}`
  }

  setBreadcrumbItems() {
    if (!this.groupKey) {
      this.groupKey = this.recipe.meta.filters.category[0];
    }

    this.breadcrumbItems = [
      { label: this.uiData.breadcrumbRootLabel, url: this.uiData.breadcrumbRootLink, target: '_self' },
      { label: this.recipe.meta.filters.category[0], url: `${this.uiData.breadcrumbRootLink}${this.recipe.meta.filters.category[0]}/`, target: '_self', ...this.utilsService.breadcrumbStyle }
    ];

    if (this.groupSubKey) {
      this.breadcrumbItems.push(
        { label: this.groupSubKey, url: `${this.uiData.breadcrumbRootLink}${this.groupKey}/${this.groupSubKey}/`, target: '_self', ...this.utilsService.breadcrumbStyle }
      );
    }
  }  
}
