import { Component, HostListener, inject } from '@angular/core';
import { StateService, JsonFilesDataService, UtilsService } from '../../services';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ScrollTopModule } from 'primeng/scrolltop';
import { PermanentStateModel } from '../../models';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [DialogModule, ButtonModule, CommonModule, TooltipModule, ScrollTopModule],
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  jsonFilesDataService = inject(JsonFilesDataService);
  utilsService = inject(UtilsService);
  stateService = inject(StateService);

  uiData: any;
  state!: PermanentStateModel;
  dialogVisible: boolean = false;
  cookiesConsentShowed: boolean = false;

  ngOnInit(){
    this.jsonFilesDataService.getFooterUiData((data: any) => this.uiData = data) 
    this.state = this.stateService.permanentState;        
  }

  @HostListener('window:load')
  onLoad() {
    this.showConsent();
  }

  accepted(){
    this.dialogVisible = false;
    this.state.cookiesAccepted = true;
    this.stateService.setPermanentState(this.state);    
  }

  showConsent() {
    if(
      !this.stateService.IsMobile
      && !this.utilsService.isBraveBrowser() 
      && !this.cookiesConsentShowed 
      && this.canNotViewWithoutConsent()
    ){
      setTimeout(() => {
        this.cookiesConsentShowed = true;
        this.dialogVisible = true;
      }, 15000);
    }        
  }

  canNotViewWithoutConsent() {
    return !this.state?.cookiesAccepted && !this.utilsService.isMetaRoute();
  }
}
