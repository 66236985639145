import { inject, Injectable } from '@angular/core';
import { LocalStorageService } from '.';
import { PermanentStateModel, TemporaryStateModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  localStorageService = inject(LocalStorageService)

  _permanentState?: PermanentStateModel;
  get permanentState(): PermanentStateModel {
    if (!this._permanentState) {
      this._permanentState = this.localStorageService.getPermanentItem<PermanentStateModel>(PermanentStateModel.key) || new PermanentStateModel();
    }
    return this._permanentState;
  }

  setPermanentState(state: PermanentStateModel) {
    this._permanentState = state;
    this.localStorageService.setPermanentItem(PermanentStateModel.key, this._permanentState);
  }

  _temporaryState!: TemporaryStateModel | null;
  get temporaryState() {
    if (!this._temporaryState) {
      this._temporaryState = this.localStorageService.getTemporaryItem<TemporaryStateModel>(TemporaryStateModel.key);
    }
    return this._temporaryState;
  }

  setTemporaryState(state: TemporaryStateModel, timeToExpireInHours: number = 168 /* 1 week default */) {
    this._temporaryState = state;
    this.localStorageService.setTemporaryItem(TemporaryStateModel.key, this._temporaryState, timeToExpireInHours);
  }

  private _isMobile?: boolean = undefined;
  public get IsMobile(){    
    if(this._isMobile === undefined){
      try {
        this._isMobile = this.localStorageService.isMobile || (window as any)?.gfbm; 
      }
      catch{
        this._isMobile = false;
      }
    }
    return this._isMobile;
  }
}
