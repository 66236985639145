import { Component } from '@angular/core';

@Component({
  selector: 'app-index-now',
  standalone: true,
  imports: [],
  templateUrl: './index-now.component.html'
})
export class IndexNowComponent {

}
