import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { JsonFilesDataService, UtilsService } from '../../services';
import { debounceTime, Subject } from 'rxjs';
import { RecipeCatalogModel } from '../../models';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [FormsModule, DialogModule, InputTextModule],
  templateUrl: './search.component.html'
})
export class SearchComponent {
  jsonFilesDataService = inject(JsonFilesDataService);  
  utilsService = inject(UtilsService);
  recipeSearchValue: string = "";
  uiData: any;
  recipesList!: Array<RecipeCatalogModel>;
  allRecipes!: Array<RecipeCatalogModel>;
  searchInput$ = new Subject<string>();

  @Input() show: boolean = false;

  @Output() showChange = new EventEmitter<boolean>();

  constructor() {
    this.searchInput$
      .pipe(debounceTime(500))
      .subscribe((searchTerm: string) => {
        searchTerm = searchTerm.toLowerCase();
        this.recipesList = [
          ...this.allRecipes
            .filter(e => e.title.toLowerCase().includes(searchTerm) || e.shortDescription.toLowerCase()
            .includes(searchTerm))
        ];
      });
  }

  ngOnInit(){
    this.jsonFilesDataService.getSearchUiData((uiData: any) => {
      this.uiData = uiData;
      this.jsonFilesDataService.getRecipesList((recipes: any) => {
        this.allRecipes = recipes.reverse();      
        this.recipesList = [...this.allRecipes];
      });
    }) 
  }  

  onHide() {
    this.showChange.emit(this.show);
  }

  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  search(searchTerm: string) {
    this.searchInput$.next(searchTerm);
  }

  ngOnDestroy() {
    this.searchInput$.complete();
  }
}