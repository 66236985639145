import { UrlSerializer, UrlTree, DefaultUrlSerializer } from "@angular/router";


export class CustomUrlSerializer implements UrlSerializer {
  parse(url: any): UrlTree {
    let dus = new DefaultUrlSerializer();
    return dus.parse(url);
  }
  serialize(tree: UrlTree): any {
    let dus = new DefaultUrlSerializer(), path = dus.serialize(tree);
    let pathArr = path.split('?');
    pathArr[0] += "/";
    let joinSeparator = pathArr.length > 1 ? "?" : "";
    path = pathArr.join(joinSeparator);
    if(path === '//'){
      path = '/home/'
    }
    return path;
  }
}
