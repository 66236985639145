@if(utilsService.IsRuntime){
    <div id="dockSkeleton" class="translate-y-100">
        <p-skeleton width="30px" height="250px" />
    </div>
}

<div id="dockContent" class="text-center hidden">
    <p-dock [model]="uiData?.dockItems" position="left">
        <ng-template pTemplate="item" let-item>                    
            <a [href]="item?.href" target="{{this.utilsService.hrefTarget}}" title="{{item.title}}">
                <img id="{{'dockImage' +  item.index}}" [src]="item.src" loading="lazy" class="w-full p-1" [alt]="item.title"/>
            </a>            
        </ng-template>
    </p-dock>
</div>
