import { DOCUMENT } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { JsonFilesDataService, RecipesService, UtilsService } from '../../services';
import { IngredientsModel, RecipeDataModel } from '../../models';
import { TableModule } from 'primeng/table';
import { DataViewModule } from 'primeng/dataview';
import { ImageModule } from 'primeng/image';
import { ButtonModule } from 'primeng/button';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-print',
  standalone: true,
  imports: [TableModule, DataViewModule, ImageModule, ButtonModule ],
  templateUrl: './print.component.html'
})
export class PrintComponent {
  document = inject(DOCUMENT);
  jsonFilesDataService = inject(JsonFilesDataService);
  recipesService = inject(RecipesService);
  utilsService = inject(UtilsService);  
  sanitizer = inject(DomSanitizer);

  uiData: any;
  recipeAddress: string = '';
  @Input() recipeKey!: string;
  recipe!: RecipeDataModel;

  ngOnInit() {
  this.recipeAddress = this.utilsService.getRecipeAddress(this.document, this.recipeKey);  

    this.jsonFilesDataService.getRecipeDetailsUiData((uiData: any) => {
      this.uiData = uiData;
      this.recipesService.getRecipe(this.recipeKey, (data: RecipeDataModel) => {
        this.recipe = data;

        this.utilsService.setPageSeoMeta({
          prefix: this.uiData.print,
          title: this.recipe.meta.title, 
          description: this.recipe.meta.shortDescription,
          image: `${this.uiData.siteAddress}/assets/recipes/${this.utilsService.language}/${this.recipeKey}/${this.recipe?.details?.titleImage}`
        });
      })
    });
  }
  
  printDate(){
    return `${this.uiData?.printedDate} ${new Date().toLocaleDateString()}`;
  }

  print(){
    try{
      this.document.defaultView?.print()
    }catch{}    
  }

  recipeHasAnyNotes(ingredients: IngredientsModel[] | undefined): any {
    return ingredients?.find(e => e.notes.length > 0) !== undefined;
  }
}
