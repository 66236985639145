import { Component, HostListener, inject } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { SkeletonModule } from 'primeng/skeleton';
import { JsonFilesDataService, RecipesService, UtilsService } from '../../services';
import { RecipeCategoryModel } from '../../models';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CarouselModule, ButtonModule, SkeletonModule],
  templateUrl: './home.component.html'
})
export class HomeComponent {
  jsonFilesDataService = inject(JsonFilesDataService);
  recipesService = inject(RecipesService);
  utilsService = inject(UtilsService);
  sanitizer = inject(DomSanitizer);
  document = inject(DOCUMENT);
  
  latestRecipeIndex = 0;
  uiData: any;
  recipesList: any;
  recipeGroups: Array<RecipeCategoryModel> = [];

  @HostListener('window:load')
  onLoad() {
    console.log('home onLoad...')
    this.utilsService.CheckIfLoadCompleted(
      () => this.latestRecipeIndex > 0 ? `homeCarouselImage${this.latestRecipeIndex - 1}` : null, 
      () => 'recipeHomeSkeleton', 
      () => 'recipeHomeContent', 
      'col-12', 
      (e: any) => e !== null)
  }

  ngOnInit(){    
    this.jsonFilesDataService.getRecipesList((recipes: any) => {
      this.recipesList = recipes.reverse();
      this.jsonFilesDataService.getHomeUiData((home: any) => {
        this.uiData = home;
        const newKey = this.uiData?.newRecipesCategoryKey;

        this.uiData.categoriesMeta.forEach((category:any) => {
          if(category.name === newKey){
            this.recipeGroups.push({
                name: category.name,
                label: category.label,
                title: category.title,
                link: category.link, 
                recipes: this.recipesList.slice(0, Math.min(6, this.recipesList.length))
                  .map((e: any)=> ({...e, index: this.latestRecipeIndex++}))
            });            
          }
          else{
            let list = this.recipesList.filter((r:any) => r.filters.category.find((c:any) => c === category.name))            
            this.recipeGroups.push({
              name: category.name,
              label: category.label,
              title: category.title,
              link: category.link, 
              recipes: list.slice(0, Math.min(6, list.length))
                .map((e: any)=> ({...e, index: this.latestRecipeIndex++}))
            });
          }

          this.utilsService.setPageSeoMeta({
            title: this.uiData.title, 
            description: this.uiData.pageDescription,
            image: this.uiData.logo
          });
        })
      });
    });
  }
}
