<header class="pb-3">
    <div class="card shadow-1">
        <p-menubar [model]="recipesCategories">
            <ng-template pTemplate="start">
                <a href="/home/" class="logo-link" title="{{uiData?.homeIconTitle}}">
                    <div>
                        <img src="{{uiData?.logo}}" height="45px" alt="{{uiData?.title}}" loading="lazy" />
                        <label class="pl-2 cursor-pointer">{{uiData?.title}}&nbsp;</label>
                    </div>
                </a>
            </ng-template>
            <ng-template pTemplate="item" let-item let-root="root">
                @if(!item.href){
                    <span [class]="linkClass(item)">
                        <span *ngIf="item.icon" [class]="item.icon">&nbsp;</span>
                        <span [class.font-medium]="item.isRoot" class="capitalize" title="{{item?.title}}">{{ item.label }}</span>
                    </span>
                }
                @else{
                    <a [class]="linkClass(item)" href="{{item.href}}">
                        <span *ngIf="item.icon" [class]="item.icon">&nbsp;</span>
                        <span [class.font-medium]="item.isRoot" class="capitalize" title="{{item?.title}}">{{ item.label }}</span>
                    </a>
                }
            </ng-template>
            <ng-template pTemplate="end">
                <div class="flex align-items-center gap-2">
                    <p-button class="ui-button" icon="pi pi-filter" (onClick)="showFilterDialog = true" [rounded]="true" [raised]="true" severity="warning" />
                    <p-button class="ui-button" icon="pi pi-search" (onClick)="showSearchDialog = true" [rounded]="true" [raised]="true" severity="warning" />
                </div>
            </ng-template>
        </p-menubar>
    </div>
</header>

<p-progressBar mode="indeterminate" *ngIf="loadingService.loading$ | async" [style]="{ height: '6px' }" />

<app-filter [(show)]="showFilterDialog"></app-filter>
<app-search [(show)]="showSearchDialog"></app-search>