<div class="flex flex-column align-items-center">
    <div class="sidebar-block border-round shadow-1 mb-5 font-bold w-full">
        <app-about></app-about>
    </div>
    <div class="sidebar-ads mb-3 mt-3  w-full">
        <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
            data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
    </div>
    <div class="sidebar-block mb-3 mt-3 w-full">
        <app-recommended-recipes groupType="top"></app-recommended-recipes>
    </div>
    <div class="sidebar-ads mb-3 mt-3 w-full">
        <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
            data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
    </div>
    <div class="sidebar-block mb-3 mt-3 w-full">
        <app-recommended-recipes groupType="season"></app-recommended-recipes>
    </div>

    <!-- sliding adds -->
    <div name="add" style="display: none;">
        <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
            data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
    </div>    
    <div name="add" style="display: none;">
        <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
            data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
    </div>    
    <div name="add" style="display: none;">
        <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
            data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
    </div>    
    <div name="add" style="display: none;">
        <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
            data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
    </div>    
    <div name="add" style="display: none;">
        <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
            data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
    </div>    
    <div name="add" style="display: none;">
        <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
            data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
    </div>    
    <div name="add" style="display: none;">
        <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
            data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
    </div>    
    <div name="add" style="display: none;">
        <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
            data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
    </div>    
    <div name="add" style="display: none;">
                  <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
            data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
    </div>    
    <div name="add" style="display: none;">
                  <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
            data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
    </div>    

</div>
        