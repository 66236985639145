import { TagModel } from "./TagModel";


export abstract class PredefinedTags {

    public static GF = { "key": "GF", "value": "Gluten Free", "style": "text-fab-orange" };
    public static LC = { "key": "LC", "value": "Low Carbs", "style": "text-fab-green" };
    public static SF = { "key": "SF", "value": "Sugar Free", "style": "text-fab-purple" };
    public static DF = { "key": "DF", "value": "Dairy Free", "style": "text-fab-blue" };
    public static OF = { "key": "OF", "value": "Oil Free", "style": "text-fab-lightseagreen" };
    public static YF = { "key": "YF", "value": "Yeast Free", "style": "text-fab-gray" };
    public static EF = { "key": "EF", "value": "Egg Free", "style": "text-fab-brown1" };
    public static VG = { "key": "VG", "value": "Vegan", "style": "text-fab-fuchsia" };
    public static NF = { "key": "NF", "value": "Nut Free", "style": "text-fab-darkbrown" };

    public static getTag(key: string): TagModel {
        return (PredefinedTags as any)[key];
    }

    public static allTags(): Array<any> {
        return [
            PredefinedTags.GF,
            PredefinedTags.LC,
            PredefinedTags.SF,
            PredefinedTags.DF,
            PredefinedTags.OF,
            PredefinedTags.YF,
            PredefinedTags.EF,
            PredefinedTags.VG,
            PredefinedTags.NF
        ];
    }
}
