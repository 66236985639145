<ng-container *ngTemplateOutlet="gridPaginator"></ng-container> 

<div class="col-12">
    <div class="grid">
        @for (recipe of gridRecipes; track recipe.recipeKey) {
        <div class="col-12 md:col-6 lg:col-4 xl:col-3">
            <div class="text-center border-round-sm bg-primary font-bold">
                <a href="{{utilsService.getRecipeDetailRoute(recipe.recipeKey, groupKey(), groupSubKey())}}" class="card-link">
                    <p-card header="{{recipe.title}}">
                        <ng-template pTemplate="header">
                            <div class="relative mx-auto">
                                <img id="{{'recipesListImage' + recipe.index}}" loading="lazy" alt="{{recipe.title}}" class="img-link"
                                    src="/assets/recipes/{{utilsService.language}}/{{recipe.recipeKey}}/thumbnails/{{recipe.titleImage}}" />
                                @if (recipe?.video) {
                                <div
                                    class="absolute video-icon border-1 border-pink-600 border-circle w-2rem h-2rem bg-white flex align-items-center justify-content-center">
                                    <i class="pi pi-youtube red"></i>
                                </div>
                                }
                                @if (recipe?.isFavorite) {
                                <div
                                    class="absolute favorite-icon border-1 border-yellow-500 border-circle w-2rem h-2rem bg-white flex align-items-center justify-content-center">
                                    <i class="pi pi-heart yellow "></i>
                                </div>
                                }
                            </div>
                        </ng-template>
                        <p class="p-card-desc">
                            {{utilsService.removeHtmlTags(recipe.shortDescription)}}
                        </p>
                        <ng-template pTemplate="footer">
                            <div class="flex gap-3 mt-1">
                                <p-button label="Read Recipe" class="w-full" styleClass="w-full" />
                            </div>
                        </ng-template>
                    </p-card>
                </a>
            </div>
        </div>
        }
    </div>
</div>

<ng-container *ngTemplateOutlet="gridPaginator"></ng-container>

<ng-template #gridPaginator>
    @if(showPaginator){
        <div class="col-12 inline-flex">
            <div class="hidden md:block align-items-center justify-content-center w-full">
                <!-- Hide on a small screen -->
                <p-paginator [showCurrentPageReport]="true" [pageLinkSize]="3" [showPageLinks]="true"
                    (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="recipesCounts()"
                    [rowsPerPageOptions]="[12, 24, 36]"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} recipes"></p-paginator>
            </div>
            <div class="block md:hidden align-items-center justify-content-center w-full">
                <!-- Visible on a small screen -->
                <p-paginator [showPageLinks]="true" [pageLinkSize]="1" (onPageChange)="onPageChange($event)" [first]="first"
                    [rows]="rows" [totalRecords]="recipesCounts()" [rowsPerPageOptions]="[24, 36, 48]"></p-paginator>    
            </div>
        </div>
    }        
</ng-template>