<p-fieldset>
    <ng-template pTemplate="header">
        <div class="flex align-items-center gap-2 px-2">
            <p-avatar size="large" image="{{uiData?.src}}" shape="circle" />
            <span class="font-bold">{{uiData?.title}}</span>
        </div>
    </ng-template>
    
    @if(utilsService.IsRuntime){
        <div id="{{uiData?.title + 'Skeleton'}}" class="grid">
            <div class="col-6">
                <p-skeleton width="100%" height="150px" />
                <p-skeleton width="5rem" styleClass="mt-2" />
            </div>
            <div class="col-6">
                <p-skeleton width="100%" height="150px" />
                <p-skeleton width="5rem" styleClass="mt-2" />
            </div>
            <div class="col-6">
                <p-skeleton width="100%" height="150px" />
                <p-skeleton width="5rem" styleClass="mt-2" />
            </div>
            <div class="col-6">
                <p-skeleton width="100%" height="150px" />
                <p-skeleton width="5rem" styleClass="mt-2" />
            </div>
        </div>
    }

    <div class="grid mt-1 hidden" id="{{uiData?.title + 'Content'}}">
        @for (recipe of recipesList; track $index; let idx = $index) {
            <div class="col-6">
                <a href="/recipe-detail/{{recipe.recipeKey}}/" class="recommended-link">
                    <div class="flex align-items-center justify-content-center">
                        <div class="border-1 surface-border border-round p-2">
                            <div class="mb-1">
                                <div class="relative mx-auto">
                                    <img id="{{uiData?.title + 'Image' + idx}}" src="/assets/recipes/{{utilsService.language}}/{{recipe.recipeKey}}/thumbnails/{{recipe.titleImage}}"
                                            [alt]="recipe.title" class="w-full border-round" loading="lazy" />
                                    @if (recipe?.video) {
                                        <div class="absolute video-icon border-1 border-pink-600 border-circle w-2rem h-2rem bg-white flex align-items-center justify-content-center">
                                            <i class="pi pi-youtube red"></i>
                                        </div>                                            
                                    }
                                    @if (recipe?.isFavorite) {
                                        <div class="absolute favorite-icon border-1 border-yellow-500 border-circle w-2rem h-2rem bg-white flex align-items-center justify-content-center">
                                            <i class="pi pi-heart yellow "></i>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div class="mb-2 text-sm one-line-text">
                                {{ recipe.title }}
                            </div>
                        </div>
                    </div>
                </a>
            </div>    
        }
    </div>
</p-fieldset>