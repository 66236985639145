import { Component, HostListener, inject } from '@angular/core';
import { DockModule } from 'primeng/dock';
import { JsonFilesDataService, UtilsService } from '../../services';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-dock',
  standalone: true,
  imports: [DockModule, SkeletonModule],
  templateUrl: './dock.component.html'
})
export class DockComponent {
  jsonFilesDataService = inject(JsonFilesDataService);
  utilsService = inject(UtilsService);
  uiData: any;
  latestImageIndex: number = 0;

  @HostListener('window:load')
  onLoad() {
    this.utilsService.CheckIfLoadCompleted(
      () => this.latestImageIndex > 0 ? `dockImage${this.latestImageIndex - 1}` : null, 
      () => 'dockSkeleton', 
      () => 'dockContent', 
      'text-center', 
      (e: any) => e !== null
    );
  }
  
  ngOnInit(){
    this.jsonFilesDataService.getDockUiData((data:any) => {
      this.uiData = data
      this.uiData.dockItems = data.dockItems.map((e:any)=> ({...e, index: this.latestImageIndex++}))
    })  
  }
}
