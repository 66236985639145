import { Component, HostListener, inject, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { JsonFilesDataService, RecipesService, UtilsService } from '../../services';
import { IngredientsModel, PredefinedTags, RecipeDataModel, UnitModel } from '../../models';
import { DOCUMENT, NgOptimizedImage } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { InputGroupModule } from 'primeng/inputgroup';
import { TagModule } from 'primeng/tag';
import { DataViewModule } from 'primeng/dataview';
import { AvatarModule } from 'primeng/avatar';
import { ImageModule } from 'primeng/image';
import { GalleriaModule } from 'primeng/galleria';
import { SpeedDialModule } from 'primeng/speeddial';
import { SkeletonModule } from 'primeng/skeleton';
import * as math from 'mathjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-recipe-detail',
  standalone: true,
  imports: [
    NgOptimizedImage, BreadcrumbModule, OverlayPanelModule, TableModule, InputGroupModule,
    TagModule, DataViewModule, AvatarModule, ImageModule, GalleriaModule, SpeedDialModule, SkeletonModule
  ],
  templateUrl: './recipe-detail.component.html'
})
export class RecipeDetailComponent {
  jsonFilesDataService = inject(JsonFilesDataService);
  recipesService = inject(RecipesService);
  utilsService = inject(UtilsService);
  document = inject(DOCUMENT);
  sanitizer = inject(DomSanitizer);

  breadcrumbItems: MenuItem[] | undefined;
  uiData: any;
  recipe!: RecipeDataModel;
  tags: Array<any> = [];
  originalRecipe!: RecipeDataModel;
  resizeFactor: number = 1;
  layout: any = 'grid';
  isSmallScreen: boolean = false;
  speedDialItems!: MenuItem[];
  displayGalleria: boolean = false;
  galleryData: any[] | undefined = [];
  allTags: Array<any> = PredefinedTags.allTags();

  @Input() groupKey!: string;
  @Input() groupSubKey!: string;
  @Input() recipeKey!: string;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = (this.document.defaultView as any).innerWidth < 600
    this.setSpeedDialItems(this.isSmallScreen);
  }

  @HostListener('window:load')
  onLoad() {
    this.isSmallScreen = (this.document.defaultView as any).innerWidth < 600
    this.setSpeedDialItems(this.isSmallScreen);
    this.utilsService.CheckIfLoadCompleted(
      () => 'titleImage', 
      () => 'recipeSkeleton', 
      () => "recipeContent", 
      "grid", 
      (e: any) => e !== null);
  }

  ngOnInit() {
    this.jsonFilesDataService.getRecipeDetailsUiData((uiData: any) => {
      this.uiData = uiData;
      this.recipesService.getRecipe(this.recipeKey, (data: RecipeDataModel) => {
        this.recipe = data;
        this.originalRecipe = JSON.parse(JSON.stringify(data));
        this.setBreadcrumbItems();
        this.setGalleryData();
        this.setTags();
        this.utilsService.setPageSeoMeta({
          prefix: this.uiData.view,
          suffix: this.uiData.recipe,
          title: this.recipe.meta.title,
          description: this.recipe.meta.shortDescription,
          image: `${this.uiData.siteAddress}assets/recipes/${this.utilsService.language}/${this.recipeKey}/${this.recipe?.details?.titleImage}`
        });
      })
    });
  }

  setTags() {
    this.tags = [];
    this.recipe.meta.filters.tag.forEach(e => {
      this.tags.push(PredefinedTags.getTag(e))
    })
  }

  setBreadcrumbItems() {
    if (!this.groupKey) {
      this.groupKey = this.recipe.meta.filters.category[0];
    }

    this.breadcrumbItems = []

    this.breadcrumbItems = [
      { label: this.uiData.breadcrumbRootLabel, url: this.uiData.breadcrumbRootLink, target: '_self' },
      { label: this.recipe.meta.filters.category[0], url: `${this.uiData.breadcrumbRootLink}${this.recipe.meta.filters.category[0]}/` , target: '_self', ...this.utilsService.breadcrumbStyle }
    ];

    if (this.groupSubKey) {
      this.breadcrumbItems.push(
        {label: this.groupSubKey, url: `${this.uiData.breadcrumbRootLink}${this.groupKey}/${this.groupSubKey}/`, target: '_self', ...this.utilsService.breadcrumbStyle}
      );
    }
  }

  setGalleryData() {
    const steps = this.recipe.details.steps.map((e, i) => {
      var result = {
        image: e.image,
        title: `${this.uiData.step} ${i + 1}:`,
        desc: e.desc
      };
      return result
    });

    this.galleryData = [
      {
        image: this.recipe.meta.titleImage,
        title: this.uiData.recipeDescription,
        desc: this.recipe.details.descriptions.join(' ')
      },
      ...steps
    ]
  }

  setSpeedDialItems(isSmallScreen: boolean) {
    this.speedDialItems = [];

    this.speedDialItems.push({
      icon: 'pi pi-youtube',
      command: () => this.watchVideo()
    });

    this.speedDialItems.push({
      icon: 'pi pi-arrows-alt',
      command: () => this.showZoomView(),
      visible: !isSmallScreen
    });

    this.speedDialItems.push({
      icon: 'pi pi-send',
      command: () => this.utilsService.shareRecipe(this.recipe.meta)
    });

    this.speedDialItems.push({
      icon: 'pi pi-print',
      target: '_blank',
      url: "/print/" + this.recipeKey + "/",
      visible: !isSmallScreen
    });

    this.speedDialItems.push({
      icon: 'pi pi-arrow-left',
      command: () => history.back()
    });
  }

  recipeHasAnyNotes(ingredients: IngredientsModel[] | undefined): any {
    return ingredients?.find(e => e.notes.length > 0) !== undefined;
  }

  showZoomView() {
    this.displayGalleria = true
  }

  print() {
    this.document?.defaultView?.open('/print/' + this.recipeKey + '/', '_blank');
  }

  watchVideo() {
    this.document?.defaultView?.open(this.utilsService.videoRoute(this.recipe.meta, this.groupKey, this.groupSubKey), '_blank');
  }

  getResizeFactor() {
    return this.resizeFactor + (this.resizeFactor > 1 ? this.uiData?.portions : this.uiData?.portion);
  }

  resizeRecipe(increment: number) {
    if (this.resizeFactor === 1 && increment < 1) {
      return;
    }

    this.resizeFactor = Math.round((this.resizeFactor + increment) * 10) / 10;
    this.recipe = JSON.parse(JSON.stringify(this.originalRecipe));

    this.recipe?.details.ingredients?.forEach(i => {
      i.metricUnits.forEach(e => e = this.resizeUnit(e));
      if (!this.recipe.details.ingredientsWithSingleUnits) {
        i.usUnits.forEach(e => e = this.resizeUnit(e));
      }
    });

    this.recipe?.details.additionalIngredients?.forEach(ai => {
      ai.ingredients.forEach(i => {
        i.metricUnits.forEach(e => e = this.resizeUnit(e));
        if (!this.recipe.details.ingredientsWithSingleUnits) {
          i.usUnits.forEach(e => e = this.resizeUnit(e));
        }
      })
    });
  }

  resizeUnit(unit: UnitModel) {
    if (unit.value || unit.fractionValue) {
      let evalValue = (unit.value ? unit.value : '')
        + (unit.fractionValue ? ' + ' + unit.fractionValue : '')
        + ' * ' + this.resizeFactor;

      let result = math.evaluate(evalValue);

      let wholeNumberString = Math.trunc(result).toString();
      let remainingNumber = result % 1;
      let fractions = math.fraction(remainingNumber)
      let fractionsString = math.format(fractions, { fraction: 'ratio' })

      unit.value = wholeNumberString === '0' ? '' : wholeNumberString;
      unit.fractionValue = fractionsString === '0/1' ? '' : fractionsString;
    }
    return unit;
  }
}