import { Component, inject } from '@angular/core';
import { JsonFilesDataService, UtilsService } from '../../services';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-of-use',
  standalone: true,
  imports: [],
  templateUrl: './terms-of-use.component.html'
})
export class TermsOfUseComponent {
  jsonFilesDataService = inject(JsonFilesDataService);
  utilsService = inject(UtilsService)
  sanitizer = inject(DomSanitizer);
  
  uiData: any;

  ngOnInit() {
    this.jsonFilesDataService.getTermsOfUseUiData((uiData: any) => {
      this.uiData = uiData;
      this.utilsService.setPageSeoMeta({title: this.uiData.title, description: this.uiData.title});
    });    
  }
}
