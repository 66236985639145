import { inject, Injectable } from '@angular/core';
import { JsonFilesDataService } from '.';
import { MenuNode, RecipeCatalogModel, RecipeDataModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class RecipesService {  
  private jsonFilesDataService = inject(JsonFilesDataService);
  private recipesCategories?: MenuNode[] = undefined;
  
  getRecipe(recipeKey: string | null, callback: Function) {
    let result = new RecipeDataModel();
    this.jsonFilesDataService
      .getRecipesList((recipesList:any) => {          
        result.meta = recipesList.find((e:any) => e.recipeKey === recipeKey) || recipesList[0];
        this.jsonFilesDataService.getRecipe(result.meta.recipeKey, (recipe:any) =>{
          result.details = recipe;
          callback(result);
        }) 
      });
  }

  getCategories(allItemName: string | null, categoriesMeta: any | null, callback: Function){
    if(this.recipesCategories === undefined){
      this.jsonFilesDataService
        .getRecipesList((data:any) => {          
          this.recipesCategories = this.buildCategories(allItemName, categoriesMeta, data);
          callback(this.recipesCategories);
        });
    }
    else{
      callback(this.recipesCategories);
    }
  }

  private buildCategories(allItemName: string | null, categoriesMeta: any | null, data : RecipeCatalogModel[]){   
    let result = new Array<MenuNode>();

    categoriesMeta.forEach((category: any) => {
      let categoryData = data.filter(e => e.filters.category
          .includes(category.name))
          .map(e => e.filters);

      let subCategoryData = [...new Set(categoryData.flatMap(e => e.subCategory))];
      
      let menuNode: MenuNode = {
        label: category.name,
        title: category.title,
        isRoot: true
      }  

      if(allItemName){
        menuNode.items = [{ label: allItemName, title: allItemName,  href: `${category.link}`, isRoot: true }];
      }

      subCategoryData.forEach(subCategory => {
        menuNode.items?.push({
          label: subCategory,
          title: subCategory,
          href: `${category.link}${subCategory}/`,
          isRoot: false
        });
      });

      result.push(menuNode);
    });

    return result
  }
}
