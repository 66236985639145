<p-dialog header="{{uiData?.consent?.title}}" [modal]="true" [(visible)]="dialogVisible" [style]="{ width: '25rem' }">
  @for (row of uiData?.consent?.rows; track $index){
    <p>{{row}}</p>
  } 

  <ul class="mt-2">
    @for (link of uiData?.links; track $index) {
      <li><a href="{{link.route}}" class="text-primary-900 text-sm" target="{{this.utilsService.hrefTarget}}">{{link.title}}</a></li>              
    }
  </ul>

    <div class="flex justify-content-end gap-2">
        <p-button label="{{uiData?.consent?.ok}}" (onClick)="accepted()" />
    </div>
</p-dialog>

<!-- {{uiData | json}} -->

<footer class="flex flex-column bg-footer text-white text-center p-3 mt-3">
    <div class="flex flex-row gap-1 justify-content-center align-items-center">
        @for (social of uiData?.social; track $index) {
          <a href="{{social.href}}" target="{{this.utilsService.hrefTarget}}" title="{{social.title}}" pTooltip="{{social.title}}">
              <img src="{{social.src}}" loading="lazy" alt="{{social.title}}"> 
          </a>
        }
    </div>
    <div class="uppercase mt-2">{{uiData?.appsHeader}}</div>
    <div class="flex flex-row gap-1 justify-content-center align-items-center underline mt-2">
      @for (app of uiData?.apps; track $index) {
        <a title="{{app.title}}" class="cursor-pointer" pTooltip="{{app.title}}" target="{{this.utilsService.hrefTarget}}">
          <img class="w-7rem" loading="lazy" src="{{app.src}}" alt="{{app.title}}"> 
        </a>
      }
    </div>
    <div class="text-sm mt-2">{{uiData?.copyright}}</div>
    <div class="flex flex-row gap-1 justify-content-center align-items-center underline mt-2">
      @for (link of uiData?.links; track $index) {
        <a href="{{link.route}}" class="text-white text-xs" target="{{this.utilsService.hrefTarget}}">{{link.title}}</a>
      }
    </div>
</footer>

<p-scrollTop 
  target="window" [threshold]="100" behavior="smooth" 
  icon="pi pi-arrow-up" title="{{uiData?.scrollUp}}" 
/>