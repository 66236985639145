import { FilterValue } from "./FilterValue";


export class Filters {
    categories: FilterValue[] = [];
    selectedCategories: FilterValue[] = [];
    flours: FilterValue[] = [];
    selectedFlours: FilterValue[] = [];
    labelReadings: FilterValue[] = [];
    selectedReadings: FilterValue[] = [];
}
