<div class="col-12">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="utilsService.breadcrumbHome"/>
</div>

@if(utilsService.IsRuntime){
    <div id="recipeSkeleton" class="col-12">
        <div class="flex justify-content-between mb-3">            
            <p-skeleton height="1.5rem" width="10rem" styleClass="mb-2" />
            <p-skeleton height="1.5rem" width="5rem" styleClass="mb-2" />
        </div>
        <p-skeleton width="100%" borderRadius="7px" height="250px" />
    
        <div class="mt-3">
            <div class="mt-1"><p-skeleton width="100%" /></div>
            <div class="mt-1"><p-skeleton width="100%" /></div>
            <div class="mt-1"><p-skeleton width="100%" /></div>            
        </div>
        
        <div class="flex justify-content-end mt-3">
            <p-skeleton shape="circle" size="2.5rem" styleClass="mr-2" />
            <p-skeleton shape="circle" size="2.5rem" styleClass="mr-2" />
            <p-skeleton shape="circle" size="2.5rem" styleClass="mr-2" />
        </div>
    
        <div class="mt-3">
            <p-skeleton width="13rem" />
            <table class="w-full mt-2">
                <tr><td><p-skeleton width="w-3"/></td><td><p-skeleton width="w-3"/></td><td><p-skeleton width="w-3"/></td><td><p-skeleton width="w-3"/></td></tr>
                <tr><td><p-skeleton width="w-3"/></td><td><p-skeleton width="w-3"/></td><td><p-skeleton width="w-3"/></td><td><p-skeleton width="w-3"/></td></tr>
                <tr><td><p-skeleton width="w-3"/></td><td><p-skeleton width="w-3"/></td><td><p-skeleton width="w-3"/></td><td><p-skeleton width="w-3"/></td></tr>
                <tr><td><p-skeleton width="w-3"/></td><td><p-skeleton width="w-3"/></td><td><p-skeleton width="w-3"/></td><td><p-skeleton width="w-3"/></td></tr>
            </table>
        </div>
    
        <div class="mt-3">
            <p-skeleton width="13rem" />
            <div class="border-round border-1 surface-border p-3 surface-card mt-3">
                <p-skeleton width="100%" height="150px" />
                <p-skeleton width="5rem" styleClass="mt-2"/>
                <p-skeleton height=".5rem" width="100%" height="1rem" styleClass="mt-1" />
                <p-skeleton height=".5rem" width="100%" height="1rem" styleClass="mt-1" />
                <p-skeleton height=".5rem" width="100%" height="1rem" styleClass="mt-1" />
            </div>
        </div>
    
        <div class="mt-3">
            <p-skeleton width="13rem" />
            <div class="border-round border-1 surface-border p-3 surface-card mt-3">
                <p-skeleton width="100%" height="150px" />
                <p-skeleton width="5rem" styleClass="mt-2"/>
                <p-skeleton height=".5rem" width="100%" height="1rem" styleClass="mt-1" />
                <p-skeleton height=".5rem" width="100%" height="1rem" styleClass="mt-1" />
                <p-skeleton height=".5rem" width="100%" height="1rem" styleClass="mt-1" />
            </div>
        </div>        
    </div>    
}

<article id="recipeContent" class="grid hidden">
    <header class="col-12">
        <h1 class="flex justify-content-between">
            {{recipe?.meta?.title}}
            @if(recipe?.meta?.video){
                <div class="vertical-align-middle">
                    <a class="text-lg hover:underline text-yellow-700 hover:text-900" 
                        href="{{utilsService.videoRoute(recipe?.meta, groupKey, groupSubKey)}}" 
                        target="{{this.utilsService.hrefTarget}}">{{uiData?.watchVideo}}</a>
                </div>                
            }        
        </h1>            
    </header>

    <div class="col-12 flex justify-content-center p-3">
        <figure class="relative">
            @if(recipe?.details?.titleImage){
                <img id="titleImage" [alt]="recipe?.meta?.title" (click)="showZoomView()"
                    src="/assets/recipes/{{utilsService.language}}/{{recipeKey}}/{{recipe?.details?.titleImage}}"
                    loading="lazy" class="w-full cursor-pointer surface-500 hover:surface-700 hover:shadow-5 border-white hover:border-yellow-500 border-round surface-overlay border-1">
            }                        

            <figcaption class="text-2xl pt-3 pb-3 text-justify md:text-left" [innerHTML]="sanitizer.bypassSecurityTrustHtml(utilsService.getRecipeDescriptionWithoutLinks(recipe))"></figcaption>

            @if(recipe?.details?.descriptionNote){
                <mark class="recipe-detail-mark">{{recipe?.details?.descriptionNote}}</mark>   
            }                

            @if (recipe?.meta?.isFavorite) {
                <i class="absolute favorite-icon-detail pi pi-heart-fill yellow"></i>
            }
            @if (recipe?.meta?.video) {
                <a href="{{utilsService.videoRoute(recipe?.meta, groupKey, groupSubKey)}}" target="{{this.utilsService.hrefTarget}}" alt="">
                    <i class="absolute video-icon-detail pi pi-youtube red"></i>
                </a>
            }
        </figure>

    </div>

    <div class="col-12 flex justify-content-between">

        <div class="text-400">
            <!-- likes and comments todo -->
        </div>
        
        <div class="flex gap-1">
            @if (recipe?.meta?.video) {
                <p-button (onClick)="watchVideo()" class="ui-button" icon="pi pi-youtube" [rounded]="true"
                    [raised]="true" [pTooltip]="uiData?.watchVideo" [title]="uiData?.watchVideo" />                    
            }                
            <p-button (onClick)="tagsOverlay.toggle($event)" class="ui-button" icon="pi pi-info" [rounded]="true"
                [raised]="true" [pTooltip]="uiData?.tagsTitle" [title]="uiData?.tagsTitle" />
            <p-overlayPanel #tagsOverlay>
                <div class="flex flex-column gap-1">
                    <div>
                        <span class="font-medium text-900 block mb-2">{{uiData?.tagsTitle}}:</span>
                    </div>
                    @for (tag of allTags; track $index) {                            
                        <div class="inline-flex gap-1 align-items-center">
                            <div class="text-fab {{tag.style}}" [title]="tag.value">{{tag.key}}</div>
                            <div>{{tag.value}}</div>
                        </div>                            
                    }
                </div>
            </p-overlayPanel>

            @if(!this.isSmallScreen){
                <p-button class="ui-button" icon="pi pi-arrows-alt" [rounded]="true" [raised]="true"
                    [pTooltip]="uiData?.zoomTitle" [title]="uiData?.zoomTitle" (onClick)="showZoomView()"/>
            }

            <p-button class="ui-button" icon="pi pi-send" [rounded]="true" [raised]="true"
                [pTooltip]="uiData?.shareTitle" [title]="uiData?.shareTitle" (onClick)="utilsService.shareRecipe(this.recipe.meta)" />

            @if(!this.isSmallScreen){
                <p-button class="ui-button" (onClick)="print()" icon="pi pi-print" [rounded]="true" [raised]="true"
                    [pTooltip]="uiData?.printTitle" [title]="uiData?.printTitle" />
            }                
        </div>
    </div>

    <div class="col-12 flex justify-content-center">
        <div class="flex flex-wrap justify-content-center">
            @for (tag of tags; track $index) {
                <div class="text-fab {{tag.style}}" pTooltip="{{tag.value}}" title="{{tag.value}}">
                    {{tag.key}}
                </div>
            }
        </div>
    </div>

    <div class="col-12 flex justify-content-center mb-3 mt-3">
        <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
            data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
    </div>

    <section class="col-12">
        <div class="flex justify-content-between align-items-center w-full">
            <header><h2>{{uiData?.ingredients}}</h2></header>                
            <div>
                <div class="card flex flex-column md:flex-row gap-3">
                    <p-inputGroup>
                        <button type="button" class="ui-button p-1" pButton icon="pi pi-minus" (click)="resizeRecipe(-1)"></button>
                        <input type="text" class="text-center w-7rem" pInputText disabled="disabled" [value]="getResizeFactor()"/>
                        <button type="button" class="ui-button p-1" pButton icon="pi pi-plus" (click)="resizeRecipe(1)"></button>
                    </p-inputGroup>
                </div>
            </div>
        </div>

        <div class="card card w-full p-3">
            <p-table [value]="recipe?.details?.ingredients || []" 
                    styleClass="p-datatable-sm" [tableStyle]="{'min-width': '30rem'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="w-1"></th>
                        <th class="w-5">{{uiData?.name}}</th>
                        <th class="w-3">{{recipe?.details?.ingredientsWithSingleUnits ? 'Units' : 'Metric'}}</th>
                        @if(!recipe?.details?.ingredientsWithSingleUnits){
                            <th class="w-3">{{uiData?.us}}</th>
                        }      
                        @if(recipeHasAnyNotes(recipe?.details?.ingredients)){
                            <th class="w-1rem text-center">{{uiData?.tip}}</th>
                        }                            
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-ingredient>
                    <tr>
                        <td><p-tableCheckbox [value]="ingredient"/></td>
                        <td>
                            @if(!ingredient?.linkRoute){
                                <span>{{ingredient.name}}</span>
                            }                                
                            @if(ingredient?.linkRoute){
                                <a [href]="ingredient?.linkRoute" target="{{this.utilsService.hrefTarget}}" onclick="event.stopPropagation()">
                                    <u>{{ingredient.name}}</u>
                                </a>                                    
                            }
                        </td>
                        <td>
                            <span>{{utilsService.getUnits(ingredient.metricUnits)}}</span>
                        </td>
                        @if(!recipe?.details?.ingredientsWithSingleUnits){
                            <td>
                                <span>{{utilsService.getUnits(ingredient.usUnits)}}</span>
                            </td>
                        }   
                        @if(recipeHasAnyNotes(recipe?.details?.ingredients)){
                            <td>
                                @if(ingredient.notes.length > 0){
                                    <p-button (onClick)="tipOverlay.toggle($event)" 
                                        severity="success" icon="pi pi-info" 
                                        [rounded]="true" [text]="true" [raised]="true" size="small"
                                        [pTooltip]="uiData?.recipeTip" [tooltipDisabled]="this.isSmallScreen" [title]="uiData?.recipeTip">
                                        <p-overlayPanel #tipOverlay>
                                            <div class="flex flex-column gap-1 w-25rem">
                                                <div>
                                                    <p-tag severity="success" value="{{uiData?.tip}}"/>
                                                </div>
                                                <div>
                                                    <ul class="list-decimal">
                                                        @for (note of ingredient.notes; track $index) {
                                                            <li><p>{{note}}</p></li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </p-overlayPanel>                                                                                 
                                    </p-button>                                
                                }                                
                            </td>
                        }                                                     
                    </tr>
                </ng-template>
            </p-table>    
        </div>   
    </section>

    @for (additionalIngredients of recipe?.details?.additionalIngredients || []; track $index) {
        <section class="col-12">
            <header><h2>{{additionalIngredients?.title || uiData?.additionalIngredients}}</h2></header>                
            <div class="card w-full p-3">
                <p-table [value]="additionalIngredients.ingredients" styleClass="p-datatable-sm">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="w-1rem"></th>
                            <th class="w-6">{{uiData?.name}}</th>
                            <th class="w-3">{{recipe?.details?.ingredientsWithSingleUnits ? 'Units' : 'Metric'}}</th>
                            @if(!recipe?.details?.ingredientsWithSingleUnits){
                                <th class="w-3">{{uiData?.us}}</th>
                            }  
                            @if(recipeHasAnyNotes(additionalIngredients.ingredients)){
                                <th class="w-1rem text-center">{{uiData?.tip}}</th>
                            }                            
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-ingredient>
                        <tr>
                            <td><p-tableCheckbox [value]="ingredient"/></td>
                            <td>
                                @if(!ingredient?.linkRoute){
                                    <span>{{ingredient.name}}</span>
                                }
                                @if(ingredient?.linkRoute){
                                    <a [href]="ingredient?.linkRoute" target="{{this.utilsService.hrefTarget}}" onclick="event.stopPropagation()">
                                        <u>{{ingredient.name}}</u>
                                    </a>    
                                }                                
                            </td>
                            <td>
                                <span>{{utilsService.getUnits(ingredient.metricUnits)}}</span>
                            </td>
                            @if(!recipe?.details?.ingredientsWithSingleUnits){
                                <td>
                                    <span>{{utilsService.getUnits(ingredient.usUnits)}}</span>
                                </td>
                            }                            
                            @if(recipeHasAnyNotes(additionalIngredients.ingredients)){
                                <td>
                                    @if(ingredient.notes.length > 0){
                                        <p-button (onClick)="tipOverlay.toggle($event)" 
                                            severity="success" icon="pi pi-info" 
                                            [rounded]="true" [text]="true" [raised]="true" size="small"
                                            [pTooltip]="uiData?.recipeTip" [tooltipDisabled]="this.isSmallScreen" [title]="uiData?.recipeTip">
                                            <p-overlayPanel #tipOverlay>
                                                <div class="flex flex-column gap-1 w-25rem">
                                                    <div>
                                                        <p-tag severity="success" value="{{uiData?.tip}}"/>
                                                    </div>
                                                    <div>
                                                        <ul class="list-decimal">
                                                            @for (note of ingredient.notes; track $index) {
                                                                <li><p>{{note}}</p></li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </p-overlayPanel>                                                                                 
                                        </p-button>                                
                                    }                                
                                </td>
                            }                                
                        </tr>
                    </ng-template>
                </p-table>    
            </div>   
        </section>
    }


    @if(recipe?.details?.optionalIngredients?.length){
        <section class="col-12">
            <header>
                <h2>{{uiData?.optionalIngredients}} {{(recipe.details.optionalIngredientsSuffix || '') !== '' ? ' ' + recipe.details.optionalIngredientsSuffix : ''}}:</h2>    
            </header>                
            <div class="card">
                <p-table [value]="recipe?.details?.optionalIngredients || []" selectionMode="single"
                    styleClass="p-datatable-sm">
                    <ng-template pTemplate="body" let-optionalIngredient>
                        <tr>
                            <td class="w-1rem"><span class="pi pi-circle-fill green"></span></td>
                            <td><span>{{optionalIngredient}}</span></td>
                        </tr>
                    </ng-template>
                </p-table>    
            </div>   
        </section>        
    }

    @for (moreOptional of recipe?.details?.moreOptionalIngredients; track $index) {
        <section class="col-12">
            <header><h2>{{moreOptional.title}}:</h2></header>                
            <div class="card">
                <p-table [value]="moreOptional.ingredients" selectionMode="single"
                    styleClass="p-datatable-sm">
                    <ng-template pTemplate="body" let-optionalIngredient>
                        <tr>
                            <td class="w-1rem"><span class="pi pi-circle-fill green"></span></td>
                            <td><span>{{optionalIngredient}}</span></td>
                        </tr>
                    </ng-template>
                </p-table>    
            </div>   
        </section>                        
    }

    @if(recipe?.details?.equipments?.length){
        <section class="col-12">
            <header><h2>{{uiData?.equipment}}</h2></header>                
            <div class="card">
                <p-table [value]="recipe?.details?.equipments || []" selectionMode="single"
                    styleClass="p-datatable-sm">
                    <ng-template pTemplate="body" let-equipment>
                        <tr>
                            <td class="w-1rem"><span class="pi pi-circle-fill green"></span></td>
                            <td><span>{{equipment}}</span></td>
                        </tr>
                    </ng-template>
                </p-table>    
            </div>   
        </section>        
    }        

    <div class="col-12 flex justify-content-center mb-3 mt-3">
        <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
            data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
    </div>

    <section class="col-12">                                                
        <div class="card">
            <div class="card">
                <p-dataView [value]="recipe?.details?.steps" [layout]="layout">
                    <ng-template pTemplate="header">
                        <header class="flex justify-content-between align-items-center" >
                            <h2>{{uiData?.steps}}</h2>
                            @if(!this.isSmallScreen){
                                <p-dataViewLayoutOptions/>
                            }                                                                
                        </header>
                    </ng-template>                            
                    <ng-template pTemplate="list" let-steps>
                        <div class="grid grid-nogutter">
                            @for (step of steps; let first = $first; let i = $index; track $index) {
                                <div class="col-12" class="col-12">
                                    <div class="flex flex-column sm:flex-row align-items-center p-3 gap-3" [ngClass]="{ 'border-top-1 surface-border': !first }">
                                        <div class="w-20rem">
                                            <figure class="relative">
                                                <p-image 
                                                    previewImageSrc="/assets/recipes/{{utilsService.language}}/{{recipe?.meta?.recipeKey}}/{{step.image}}" 
                                                    src="/assets/recipes/{{utilsService.language}}/{{recipe?.meta?.recipeKey}}/thumbnails/{{step.image}}" 
                                                    [preview]="true" loading="lazy"
                                                    alt="{{recipe?.meta?.title}}">
                                                </p-image>
                                                <figcaption class="hidden">{{utilsService.removeHtmlTags(step.desc)}}</figcaption>
                                            </figure>
                                        </div>
                                        <div class="flex flex-column justify-content-between flex-1 gap-4 pb-2">
                                            <div class="flex flex-column justify-content-between align-items-start gap-2">
                                                <h3 class="text-lg font-medium text-900 mt-2">{{ uiData?.step + (i + 1) }}</h3>
                                                <div class="font-medium text-secondary text-sm text-justify md:text-left" [innerHTML]="sanitizer.bypassSecurityTrustHtml(step.desc)"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }                                
                        </div>
                    </ng-template>
                    <ng-template pTemplate="grid" let-steps>
                        <div class="grid grid-nogutter">
                            @for (step of steps; let i = $index; track $index) {
                                <div class="col-12 sm:col-6 md:col-6 xl:col-6 p-2">
                                    <div class="p-2 border-1 surface-border surface-card border-round flex flex-column">
                                        <div class="surface-50 flex justify-content-center border-round p-2">
                                            <div class="relative mx-auto">
                                                <figure>
                                                    <p-image class="block xl:block mx-auto border-round w-full"
                                                        previewImageSrc="/assets/recipes/{{utilsService.language}}/{{recipe?.meta?.recipeKey}}/{{step.image}}" 
                                                        src="/assets/recipes/{{utilsService.language}}/{{recipe?.meta?.recipeKey}}/thumbnails/{{step.image}}" 
                                                        [preview]="true" loading="lazy" 
                                                        alt="{{recipe?.meta?.title}}">
                                                    </p-image>
                                                    <figcaption class="hidden">{{utilsService.removeHtmlTags(step.desc)}}</figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                        <div class="flex flex-column justify-content-between md:align-items-start flex-1 gap-4 pb-2">
                                            <div class="flex flex-column justify-content-between align-items-start gap-2">
                                                <h3 class="text-lg font-medium text-900 mt-2">{{ uiData?.step + (i + 1) }}</h3>
                                                <div class="font-medium text-secondary text-md md:text-sm text-justify md:text-left" [innerHTML]="sanitizer.bypassSecurityTrustHtml(step.desc)"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </ng-template>
                </p-dataView>
            </div>
        </div>   
    </section>

    @if(recipe?.details?.comments?.length){
        <section class="col-12">
            <h2>{{uiData?.comments}}</h2> 
                @for (comment of recipe?.details?.comments; let i = $index; track $index) {
                    <ng-container>
                        <div class="flex align-items-start">
                            <div class="w-full">
                            <div class="flex vertical-align-middle">
                                <p-avatar image="/assets/recipes/{{utilsService.language}}/{{recipeKey}}/{{comment.image}}" 
                                    size="xlarge" shape="circle" ariaLabel="{{recipe?.meta?.titleImage}}" 
                                    title="{{recipe?.meta?.titleImage}}"/>
                                <h3 class="text-xl font-medium text-900 ml-1">{{comment.title}}</h3>
                            </div>
                            <p class="font-medium text-secondary text-md md:text-sm text-justify md:text-left" [innerHTML]="sanitizer.bypassSecurityTrustHtml(comment.desc)"></p>
                            </div>
                        </div>
                </ng-container>
                }                       
        </section>

        <div class="col-12 flex justify-content-center mb-3 mt-3">
            <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
                data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
        </div>        
    } 

    @if(!utilsService.IsMobile && recipe?.details?.info){
        <section class="col-12">
            <header>
                <h2>{{recipe?.meta?.title}} {{uiData?.additionalInformation}}</h2>
            </header>                
            <div class="text-md md:text-sm text-justify md:text-left" [innerHTML]="sanitizer.bypassSecurityTrustHtml(recipe?.details?.info || '')"></div>
        </section>

        <div class="col-12 flex justify-content-center mb-3 mt-3">
            <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
                data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
        </div>
    } 

</article>
     
<div class="card flex justify-content-center">
    <p-galleria 
        [value]="galleryData" 
        [(visible)]="displayGalleria" 
        [responsiveOptions]="utilsService.galleriaResponsiveOptions" 
        [containerStyle]="{'max-width': '50%'}" 
        [numVisible]="9"
        [circular]="true" 
        [fullScreen]="true" 
        [showItemNavigators]="true">
            <ng-template pTemplate="item" let-item>
                <img 
                    loading="lazy"
                    src="/assets/recipes/{{utilsService.language}}/{{recipeKey}}/{{item.image}}" [alt]="item.desc"                    
                    class="w-full block"/>
            </ng-template>
            <ng-template pTemplate="thumbnail" let-item>
                <div class="grid grid-nogutter justify-content-center">
                    <img 
                        loading="lazy"
                        src="/assets/recipes/{{utilsService.language}}/{{recipeKey}}/thumbnails/{{item.image}}" [alt]="item.desc"
                        class="block max-h-3rem"/>
                </div>
            </ng-template>
            <ng-template class="text-md md:text-sm text-justify md:text-left" pTemplate="caption" let-item>
                <h3 [innerHTML]="sanitizer.bypassSecurityTrustHtml(item.title)"></h3>
                <p [innerHTML]="sanitizer.bypassSecurityTrustHtml(item.desc)"></p>
            </ng-template>
    </p-galleria>
</div>

<div class="p-speeddial-position">
    <p-speedDial 
    [model]="speedDialItems" 
    radius="160" 
    direction="up-right"
    type="quarter-circle" 
    transitionDelay="80" 
    showIcon="pi pi-bars" 
    hideIcon="pi pi-times" 
    buttonClassName="shadow-2"/>
</div>