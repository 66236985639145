<p-dialog header="{{uiData?.title}} ({{recipesList?.length}})" [(visible)]="show" [modal]="true"
    [breakpoints]="utilsService.dialogBreakpoints" [style]="utilsService.dialogStyle" [draggable]="false"
    [resizable]="false" [maximizable]="true" (onHide)="onHide()">
    <div class="grid">
        <div class="col-12">
            <div class="flex flex-column gap-2">
                <input pInputText [(ngModel)]="recipeSearchValue" (keyup)="search(getValue($event))">
            </div>
        </div>
        <div class="col-12">
            <div class="grid mt-1">
                @for (recipe of recipesList; track $index) {
                <div class="col-6 md:col-3 lg:col-2">
                    <a href="/recipe-detail/{{recipe.recipeKey}}/" class="recommended-link">
                        <div class="flex align-items-center justify-content-center">
                            <div class="border-1 surface-border border-round p-2">
                                <div class="mb-1">
                                    <div class="relative mx-auto">
                                        <img src="/assets/recipes/{{utilsService.language}}/{{recipe.recipeKey}}/thumbnails/{{recipe.titleImage}}"
                                            [alt]="recipe.title" class="w-full border-round img-link" loading="lazy" />
                                        @if (recipe?.video) {
                                        <div
                                            class="absolute video-icon border-1 border-pink-600 border-circle w-2rem h-2rem bg-white flex align-items-center justify-content-center">
                                            <i class="pi pi-youtube red"></i>
                                        </div>
                                        }
                                        @if (recipe?.isFavorite) {
                                        <div
                                            class="absolute favorite-icon border-1 border-yellow-500 border-circle w-2rem h-2rem bg-white flex align-items-center justify-content-center">
                                            <i class="pi pi-heart yellow "></i>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div class="mb-2 text-sm">
                                    <div class="font-semibold">{{ recipe.title }}</div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                }
            </div>
        </div>
    </div>
</p-dialog>