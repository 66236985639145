@if(isPrintMode){
    <router-outlet />
}
@else{
    <app-header></app-header>

    <div class="grid grid-nogutter">
        <!-- left spacer -->
        <div class="hidden lg:block lg:col-1 xl:col-1">
            <app-dock></app-dock>
        </div>
        <!-- content  -->
        <main class="col-12 md:col-8 lg:col-7 xl:col-7 main-content">
            <router-outlet />
        </main>
        <!-- sidebar -->
        <aside class="hidden md:block col-12 md:col-4 lg:col-3 xl:col-3" style="height: 100%;">
            <app-aside></app-aside>
        </aside>        
        <!-- right spacer -->
        <div class="hidden lg:block lg:col-1 xl:col-1">
            <div class="text-center"></div>
        </div>
    </div>
    
    <app-footer></app-footer>
}