import { Component, inject, Input, input } from '@angular/core';
import { UtilsService } from '../../services';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule, PaginatorState } from 'primeng/paginator';
import { CommonModule, DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-recipes-grid',
  standalone: true,
  imports: [ CardModule, ButtonModule, PaginatorModule, CommonModule],
  templateUrl: './recipes-grid.component.html'
})
export class RecipesGridComponent {
  utilsService = inject(UtilsService);
  document = inject(DOCUMENT);

  private recipesList!: any;
  @Input() set recipes(data: any) {
    if(data){
      this.recipesList = data?.reverse();
      this.paginateResult();
    }    
  }
  groupKey = input<string>();
  groupSubKey = input<string>();

  gridRecipes: any;
  first: number = 0;
  rows: number = 12;

  paginateResult() {
    this.gridRecipes = this.recipesList?.slice(this.first, this.first + this.rows);
  }

  onPageChange(event: PaginatorState) {
    this.first = event.first || 0;
    this.rows = event.rows || 12;
    this.paginateResult();
    setTimeout(() => {
        this.document.getElementById("recipesSection")?.scrollIntoView();
    }, 10)
  }

  recipesCounts(){
    return this.recipesList?.length || 0;
  } 

  get showPaginator(){
    return (this.recipesList || []).length >= 12;
  }
}
