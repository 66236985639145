import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withComponentInputBinding, withRouterConfig } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withFetch } from '@angular/common/http';

import { UrlSerializer } from '@angular/router';
import { CustomUrlSerializer } from './CustomUrlSerializer';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes, 
      withRouterConfig({
        onSameUrlNavigation: 'reload',        
      }),
      withComponentInputBinding() /* this binds route params to component @Input() */
    ),
    provideHttpClient(withFetch()),
    {
      provide: UrlSerializer,
      useClass: CustomUrlSerializer
    }
  ]
};
