import { inject, Injectable } from '@angular/core';
import { first, shareReplay } from 'rxjs';
import { LoadingService } from './loading.service';
import { HttpClient } from '@angular/common/http';
import { RecipeCatalogModel, RecipeModel } from '../models';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class JsonFilesDataService {
    
  private http = inject(HttpClient);
  private loadingService = inject(LoadingService);
  private utilsService = inject(UtilsService);

  private getJSON(filePath: string, callback: Function) {
    this.loadingService
      .showLoaderUntilCompleted(this.http.get(filePath))
      .pipe(
        first(),
        shareReplay()
      )
      .subscribe({
        next: (json) => callback(json),
        error: error => {}
      }); 
  }

  // Header -------------------------------------------
  private headerUiData: any = undefined;

  getHeaderUiData(callback: Function) {
    if(this.headerUiData === undefined){
      this.getJSON(
        `./assets/ui-data/${this.utilsService.language}/header.json`, 
        (json: any) => {
          this.headerUiData = json;
          callback(this.headerUiData)
        }
      );
    }
    else{
      callback(this.headerUiData)
    }
  }

  // Footer -------------------------------------------
  private footerUiData: any = undefined;
  
  getFooterUiData(callback: Function){
    if(this.footerUiData === undefined){
      this.getJSON(
        `./assets/ui-data/${this.utilsService.language}/footer.json`, 
        (json: any) => {
          this.footerUiData = json;
          callback(this.footerUiData)
        }
      );
    }
    else{
      callback(this.footerUiData)
    }
  }

  // Dock -------------------------------------------
  private dockUiData: any = undefined;
  
  getDockUiData(callback: Function){
    if(this.dockUiData === undefined){
      this.getJSON(
        `./assets/ui-data/${this.utilsService.language}/dock.json`, 
        (json: any) => {
          this.dockUiData = json;
          callback(this.dockUiData)
        }
      );
    }
    else{
      callback(this.dockUiData)
    }
  } 

  // About -------------------------------------------
  private aboutUiData: any = undefined;
  
  getAboutUiData(callback: Function){
    if(this.aboutUiData === undefined){
      this.getJSON(
        `./assets/ui-data/${this.utilsService.language}/about.json`, 
        (json: any) => {
          this.aboutUiData = json;
          callback(this.aboutUiData)
        }
      );
    }
    else{
      callback(this.aboutUiData)
    }
  }

  // Recommended -------------------------------------------
  private recommendedUiData: any = undefined;

  getRecommendedUiData(callback: Function){
    if(this.recommendedUiData === undefined){
      this.getJSON(
        `./assets/ui-data/${this.utilsService.language}/recommended.json`, 
        (json: any) => {
          this.recommendedUiData = json;
          callback(this.recommendedUiData)
        }
      );
    }
    else{
      callback(this.recommendedUiData)
    }
  }

  // Home -------------------------------------------
  private homeUiData: any = undefined;

  getHomeUiData(callback: Function){
    if(this.homeUiData === undefined){
      this.getJSON(
        `./assets/ui-data/${this.utilsService.language}/home.json`, 
        (json: any) => {
          this.homeUiData = json;
          callback(this.homeUiData)
        }
      );
    }
    else{
      callback(this.homeUiData)
    }
  }

  // Recipes Categories -------------------------------------------
  private recipesCategoriesUiData: any = undefined;

  getRecipesCategoriesUiData(callback: Function){
    if(this.recipesCategoriesUiData === undefined){
      this.getJSON(
        `./assets/ui-data/${this.utilsService.language}/recipes-categories.json`, 
        (json: any) => {
          this.recipesCategoriesUiData = json;
          callback(this.recipesCategoriesUiData)
        }
      );
    }
    else{
      callback(this.recipesCategoriesUiData)
    }
  }

  // Recipe Details -------------------------------------------
  private recipeDetailsUiData: any = undefined;

  getRecipeDetailsUiData(callback: Function){
    if(this.recipeDetailsUiData === undefined){
      this.getJSON(
        `./assets/ui-data/${this.utilsService.language}/recipe-details.json`, 
        (json: any) => {
          this.recipeDetailsUiData = json;
          callback(this.recipeDetailsUiData)
        }
      );
    }
    else{
      callback(this.recipeDetailsUiData)
    }
  }  

  // RecipesList -------------------------------------------
  private recipesList?: RecipeCatalogModel[] = undefined;
  
  getRecipesList(callback: Function){
    if(this.recipesList === undefined){
      this.getJSON(
        `./assets/recipes/${this.utilsService.language}/recipes-list.json`, 
        (json: RecipeCatalogModel[]) => {
          this.recipesList = json;
          callback(this.recipesList)
        }
      );
    }
    else{
      callback(this.recipesList)
    }
  }

  // Recipe -------------------------------------------
  private recipe?: RecipeModel[] = undefined;
  
  getRecipe(recipeKey: string, callback: Function){
    if(this.recipe === undefined){
      this.getJSON(
        `./assets/recipes/${this.utilsService.language}/${recipeKey}/recipe.json`, 
        (json: RecipeModel[]) => {
          this.recipe = json;
          callback(this.recipe)
        }
      );
    }
    else{
      callback(this.recipe)
    }
  }  

  // Flours -------------------------------------------
  private floursUiData: any = undefined;

  getFloursUiData(callback: Function){
    if(this.floursUiData === undefined){
      this.getJSON(
        `./assets/ui-data/${this.utilsService.language}/flour-comparer.json`, 
        (json: any) => {
          this.floursUiData = json;
          callback(this.floursUiData)
        }
      );
    }
    else{
      callback(this.floursUiData)
    }
  }
  
  // Search -------------------------------------------
  private searchUiData: any = undefined;

  getSearchUiData(callback: Function){
    if(this.searchUiData === undefined){
      this.getJSON(
        `./assets/ui-data/${this.utilsService.language}/search.json`, 
        (json: any) => {
          this.searchUiData = json;
          callback(this.searchUiData)
        }
      );
    }
    else{
      callback(this.searchUiData)
    }
  }

  // Filter -------------------------------------------
  private filterUiData: any = undefined;

  getFilterUiData(callback: Function){
    if(this.filterUiData === undefined){
      this.getJSON(
        `./assets/ui-data/${this.utilsService.language}/filter.json`, 
        (json: any) => {
          this.filterUiData = json;
          callback(this.filterUiData)
        }
      );
    }
    else{
      callback(this.filterUiData)
    }
  }
  
  // PrivacyPolicy -------------------------------------------
  private privacyPolicyUiData: any = undefined;
  
  getPrivacyPolicyUiData(callback: Function){
    if(this.privacyPolicyUiData === undefined){
      this.getJSON(
        `./assets/ui-data/${this.utilsService.language}/privacy-policy.json`, 
        (json: any) => {
          this.privacyPolicyUiData = json;
          callback(this.privacyPolicyUiData)
        }
      );
    }
    else{
      callback(this.privacyPolicyUiData)
    }
  }

  // CookieTerms -------------------------------------------
  private cookieTermsUiData: any = undefined;
  
  getCookieTermsUiData(callback: Function){
    if(this.cookieTermsUiData === undefined){
      this.getJSON(
        `./assets/ui-data/${this.utilsService.language}/cookie-terms.json`, 
        (json: any) => {
          this.cookieTermsUiData = json;
          callback(this.cookieTermsUiData)
        }
      );
    }
    else{
      callback(this.cookieTermsUiData)
    }
  }

  // CookieTerms -------------------------------------------
  private termsOfUseUiData: any = undefined;
  
  getTermsOfUseUiData(callback: Function){
    if(this.termsOfUseUiData === undefined){
      this.getJSON(
        `./assets/ui-data/${this.utilsService.language}/terms-of-use.json`, 
        (json: any) => {
          this.termsOfUseUiData = json;
          callback(this.termsOfUseUiData)
        }
      );
    }
    else{
      callback(this.termsOfUseUiData)
    }
  }
}

  