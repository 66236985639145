<p-dialog header="{{uiData?.title}} ({{recipesList?.length}})" [(visible)]="show"
    [breakpoints]="utilsService.dialogBreakpoints" [style]="utilsService.dialogStyle" [draggable]="false"
    [resizable]="false" [maximizable]="true" (onHide)="onHide()">

    <div class="grid">
        <div class="col-12">
            <div class="grid">
                <div class="col-12 md:col-4 lg:col-4 mt-1">
                    <p-floatLabel>
                        <p-multiSelect (onClear)="filter()" (onChange)="filter()" display="chip" [filter]="true"
                            [showClear]="true" inputId="categories" [options]="filters?.categories"
                            [(ngModel)]="filters.selectedCategories" optionLabel="name" />
                        <label for="categories">{{uiData?.recipeCategories}}</label>
                    </p-floatLabel>
                </div>
                <div class="col-12 md:col-4 lg:col-4 mt-1">
                    <p-floatLabel>
                        <p-multiSelect (onClear)="filter()" (onChange)="filter()" display="chip" [filter]="true"
                            [showClear]="true" inputId="flours" [options]="filters?.flours"
                            [(ngModel)]="filters.selectedFlours" optionLabel="name" />
                        <label for="flours">{{uiData?.flours}}</label>
                    </p-floatLabel>
                </div>
                <div class="col-12 md:col-4 lg:col-4 mt-1">
                    <p-floatLabel>
                        <p-multiSelect (onClear)="filter()" (onChange)="filter()" display="chip" [filter]="true"
                            [showClear]="true" inputId="labelReadings" [options]="filters?.labelReadings"
                            [(ngModel)]="filters.selectedReadings" optionLabel="name" />
                        <label for="labelReadings">{{uiData?.labelReading}}</label>
                    </p-floatLabel>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="grid mt-1">
                @for (recipe of recipesList; track recipe) {
                <div class="col-6 md:col-3 lg:col-2">
                    <a href="/recipe-detail/{{recipe.recipeKey}}/" class="recommended-link">
                        <div class="flex align-items-center justify-content-center">
                            <div class="border-1 surface-border border-round p-2">
                                <div class="mb-1">
                                    <div class="relative mx-auto">
                                        <img src="/assets/recipes/{{utilsService.language}}/{{recipe.recipeKey}}/thumbnails/{{recipe.titleImage}}"
                                            [alt]="recipe.title" class="w-full border-round img-link" loading="lazy" />
                                        @if (recipe?.video) {
                                        <div
                                            class="absolute video-icon border-1 border-pink-600 border-circle w-2rem h-2rem bg-white flex align-items-center justify-content-center">
                                            <i class="pi pi-youtube red"></i>
                                        </div>
                                        }
                                        @if (recipe?.isFavorite) {
                                        <div
                                            class="absolute favorite-icon border-1 border-yellow-500 border-circle w-2rem h-2rem bg-white flex align-items-center justify-content-center">
                                            <i class="pi pi-heart yellow "></i>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div class="mb-2 text-sm">
                                    <div class="font-semibold">{{ recipe.title }}</div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                }
            </div>
        </div>
    </div>
</p-dialog>