<div class="flex flex-column m-2 text-center">
    <p-avatar image="./assets/images/logo/avatar.jpg" size="xlarge" shape="circle" ariaLabel="{{uiData?.label}}"
        title="{{uiData?.label}}" />

    @if(utilsService.IsRuntime){
        <div id="aboutSkeleton">
            <p-skeleton width="100%" height="250px" />
        </div>
    }

    <div id="aboutContent" class="about-text p-2 hidden">{{uiData?.text}}</div>
</div>