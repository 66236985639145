<div class="grid">

    @if(utilsService.IsRuntime){
        <div id="recipeHomeSkeleton" class="grid col-12">
            <div class="col-12 flex justify-content-between">            
                <p-skeleton height="2.2rem" width="10rem" styleClass="mb-2" />
                <p-skeleton height="2.2rem" width="7rem" borderRadius="25px" styleClass="mb-2" />
            </div>
    
            <div class="col-12 md:col-6 lg:col-4">
                <div class="border-round border-1 surface-border p-3 surface-card">
                    <p-skeleton width="100%" height="200px" />
                    <p-skeleton height=".5rem" width="100%" height="1rem" styleClass="mt-1" />
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
                <div class="border-round border-1 surface-border p-3 surface-card">
                    <p-skeleton width="100%" height="200px" />
                    <p-skeleton height=".5rem" width="100%" height="1rem" styleClass="mt-1" />
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
                <div class="border-round border-1 surface-border p-3 surface-card">
                    <p-skeleton width="100%" height="200px" />
                    <p-skeleton height=".5rem" width="100%" height="1rem" styleClass="mt-1" />
                </div>
            </div>
                   
            <div class="col-12 mt-2">
                <p-skeleton width="100%" height="200px" />
            </div>                        
        </div>
    }

    <div id="recipeHomeContent" class="col-12 hidden">
        @for (rg of this.recipeGroups; track rg.name; let idx = $index) {
            <div class="col-12 flex justify-content-between">
                <span class="font-bold text-600 text-2xl">{{rg.label}}</span>
                <a href="{{rg.link}}" title="{{rg.title}}">
                    <p-button label="Explore All" [raised]="true" [rounded]="true" size="small" icon="pi pi-list" />
                </a>
            </div>
            <div class="col-12">
                <div class="card">
                    <p-carousel [value]="rg.recipes" [numVisible]="3" [numScroll]="1" [responsiveOptions]="this.utilsService.carouselResponsiveOptions">
                        <ng-template let-recipe pTemplate="item">
                            <a href="{{utilsService.getRecipeDetailRoute(recipe.recipeKey)}}" class="card-link">
                                <div class="border-1 surface-border border-round m-1 p-2">
                                    <div class="mb-3">
                                        <div class="relative mx-auto">
                                            <img id="{{'homeCarouselImage' + recipe.index}}" placeholder priority loading="lazy" 
                                                src="/assets/recipes/{{utilsService.language}}/{{recipe.recipeKey}}/thumbnails/{{recipe.titleImage}}"
                                                [alt]="recipe.title" class="w-full border-round img-link" />
                                            @if (recipe?.video) {
                                                <div class="absolute video-icon border-1 border-pink-600 border-circle w-2rem h-2rem bg-white flex align-items-center justify-content-center">
                                                    <i class="pi pi-youtube red"></i>
                                                </div>                                            
                                            }
                                            @if (recipe?.isFavorite) {
                                                <div class="absolute favorite-icon border-1 border-yellow-500 border-circle w-2rem h-2rem bg-white flex align-items-center justify-content-center">
                                                    <i class="pi pi-heart yellow "></i>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="mb-3 font-medium one-line-text">
                                        {{ recipe.title }}
                                    </div>
                                </div>
                            </a>
                        </ng-template>
                    </p-carousel>
                </div>
            </div>
    
            @if(idx === 0 || ( idx % 3 === 0)){
                <div class="col-12 mb-1 mt-1">
                    <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
                    data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
                </div>
            }
        }
    
        @for (info of uiData?.info; track info.id; let idx = $index) {
            <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(info?.content)" class="col-12"></div>
            @if(idx % 3 === 0){
                <div class="col-12 mb-1 mt-1">
                    <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
                        data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
                </div>
            }
        }
    </div>
</div>