import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import { MultiSelectModule } from 'primeng/multiselect';
import { JsonFilesDataService, UtilsService } from '../../services';
import { Filters, PredefinedTags, RecipeCatalogModel } from '../../models';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [FormsModule, DialogModule, MultiSelectModule, FloatLabelModule],
  templateUrl: './filter.component.html'
})
export class FilterComponent {

  jsonFilesDataService = inject(JsonFilesDataService);
  utilsService = inject(UtilsService);

  @Input() show: boolean = false;

  @Output() showChange = new EventEmitter<boolean>();

  uiData: any;
  recipesList!: Array<RecipeCatalogModel>;
  allRecipes!: Array<RecipeCatalogModel>;
  filters: Filters = new Filters();

  ngOnInit() {
    this.jsonFilesDataService.getFilterUiData((uiData: any) => {
      this.uiData = uiData;
      this.jsonFilesDataService.getRecipesList((recipes: any) => {
        this.allRecipes = recipes.reverse();
        this.recipesList = [...this.allRecipes];

        this.filters.flours = [...new Set(this.allRecipes.flatMap(e => e.filters.flour))]
          .map(e => ({ code: e, name: this.capitalize(e) }));

        this.filters.categories = [...new Set(this.allRecipes.flatMap(e => e.filters.category))]
          .map(e => ({ code: e, name: this.capitalize(e) }));

        this.filters.labelReadings = PredefinedTags.allTags().map(e => ({ code: e.key, name: `${e.value} (${e.key})` }));
      });
    })
  }

  capitalize(s: string) {
    return s && s[0].toUpperCase() + s.slice(1);
  }

  onHide() {
    this.showChange.emit(this.show);
  }

  filter() {
    let filterResult = [...this.allRecipes];

    let categories = (this.filters.selectedCategories || []).map(e => e.code).join();
    let flours = (this.filters.selectedFlours || []).map(e => e.code).join();
    let readings = (this.filters.selectedReadings || []).map(e => e.code).join();

    if (categories || flours || readings) {

      if (categories) {
        filterResult = filterResult.filter(e => e.filters.category.some(i => categories.indexOf(i) > -1))
      }
      if (flours) {
        filterResult = filterResult.filter(e => e.filters.flour.some(i => flours.indexOf(i) > -1))
      }
      if (readings) {
        filterResult = filterResult.filter(e => e.filters.tag.some(i => readings.indexOf(i) > -1))
      }
    }
    this.recipesList = [...filterResult];
  }
}