<div class="col-12">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="utilsService.breadcrumbHome" />
</div> 

<article class="grid">
    <header class="col-12">
        <h1 class="flex justify-content-between">
            {{recipe?.meta?.title}} {{uiData?.video}}
            <div class="vertical-align-middle">
                <a class="text-lg hover:underline text-yellow-700 hover:text-900" 
                    href="{{utilsService.getRecipeDetailRoute(recipeKey, groupKey, groupSubKey)}}" 
                    target="{{this.utilsService.hrefTarget}}">{{uiData?.readRecipe}}</a>
            </div>            
        </h1>            
    </header>

    @if(recipe?.meta?.video){
        <div class="col-12 flex justify-content-center p-3">
            <figure class="relative">
                <div class="col-12 flex justify-content-center p-3">
                    <video controls preload class="w-full">
                        <source [src]="videoSource()" type="video/mp4">
                        <p>{{uiData?.videoAlert}}</p>
                    </video>
                </div>
                <figcaption class="text-2xl pt-3 pb-3">
                    <div class="m-2">
                        <p-table [value]="recipe?.details?.ingredients || []" selectionMode="single" dataKey="name" tableStyleClass="w-full" styleClass="p-datatable-sm">
                            <ng-template pTemplate="body" let-ingredient>
                                <tr>
                                    <td class="w-1rem"><p-tableCheckbox [value]="ingredient"/></td>
                                    <td>
                                        @if(!ingredient?.linkRoute){
                                            <span>{{ingredient.name}}</span>
                                        }
                                        @if(ingredient?.linkRoute){
                                            <a [href]="ingredient?.linkRoute" target="{{this.utilsService.hrefTarget}}" onclick="event.stopPropagation()">
                                                <u>{{ingredient.name}}</u>
                                            </a>    
                                        }                                
                                    </td>
                                    <td>
                                        <span>{{utilsService.getUnits(ingredient.metricUnits)}}</span>
                                    </td>
                                    @if(!recipe?.details?.ingredientsWithSingleUnits){
                                        <td>
                                            <span>{{utilsService.getUnits(ingredient.usUnits)}}</span>
                                        </td>
                                    }                            
                                </tr>
                            </ng-template>
                        </p-table>                        
                    </div>                    
                </figcaption>
            </figure>    
        </div>
    }
    
    <div class="col-12 mb-3 mt-3">
        <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
            data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
    </div>
</article>

