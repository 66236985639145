<div class="grid">
    <div class="col-12">
        <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="utilsService.breadcrumbHome"/>
    </div>

    @if(utilsService.IsRuntime){
        <div id="recipeListSkeleton" class="grid col-12">
            @if(!isCategoryView){       
                <div class="col-12 md:col-6 lg:col-4">
                    <div class="border-round border-1 surface-border p-3 surface-card">
                        <p-skeleton width="100%" height="300px" />
                        <p-skeleton height=".5rem" width="100%" height="1rem" styleClass="mt-1" />
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-4">
                    <div class="border-round border-1 surface-border p-3 surface-card">
                        <p-skeleton width="100%" height="300px" />
                        <p-skeleton height=".5rem" width="100%" height="1rem" styleClass="mt-1" />
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-4">
                    <div class="border-round border-1 surface-border p-3 surface-card">
                        <p-skeleton width="100%" height="300px" />
                        <p-skeleton height=".5rem" width="100%" height="1rem" styleClass="mt-1" />
                    </div>
                </div>
            }
            @else{
                <div class="col-12 flex justify-content-between">            
                    <p-skeleton height="2.2rem" width="10rem" styleClass="mb-2" />
                    <p-skeleton height="2.2rem" width="7rem" borderRadius="25px" styleClass="mb-2" />
                </div>
        
                <div class="col-12 md:col-6 lg:col-4">
                    <div class="border-round border-1 surface-border p-3 surface-card">
                        <p-skeleton width="100%" height="200px" />
                        <p-skeleton height=".5rem" width="100%" height="1rem" styleClass="mt-1" />
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-4">
                    <div class="border-round border-1 surface-border p-3 surface-card">
                        <p-skeleton width="100%" height="200px" />
                        <p-skeleton height=".5rem" width="100%" height="1rem" styleClass="mt-1" />
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-4">
                    <div class="border-round border-1 surface-border p-3 surface-card">
                        <p-skeleton width="100%" height="200px" />
                        <p-skeleton height=".5rem" width="100%" height="1rem" styleClass="mt-1" />
                    </div>
                </div>
                       
                <div class="col-12 mt-2">
                    <p-skeleton width="100%" height="200px" />
                </div>          
            }                        
        </div>
    }
    
    <div id="recipeListContent" class="col-12 hidden">
        @for (rg of this.carouselRecipes; track rg.label; let idx = $index) {
            <div class="col-12 flex justify-content-between">
                <span class="font-bold text-600 text-2xl capitalize">{{rg.label}}</span>
                @if(rg.recipes.length > 2){
                <a href="{{uiData.breadcrumbRootLink}}{{groupKey}}/{{rg.label}}/" title="{{rg.label}} {{groupKey}}">
                    <p-button label="Explore All" [raised]="true" [rounded]="true" size="small" icon="pi pi-list" />
                </a>
                }
            </div>
            <div class="col-12">
                <div class="card">
                    <p-carousel [value]="rg.recipes" [numVisible]="3" [numScroll]="1"
                        [responsiveOptions]="this.utilsService.carouselResponsiveOptions">
                        <ng-template let-recipe pTemplate="item">
                            <a href="{{utilsService.getRecipeDetailRoute(recipe.recipeKey, groupKey, groupSubKey)}}" class="card-link">
                                <div class="border-1 surface-border border-round m-1 p-2">
                                    <div class="mb-3">
                                        <div class="relative mx-auto">
                                            <img id="{{'recipesListImage' + recipe.index}}" loading="lazy" src="/assets/recipes/{{utilsService.language}}/{{recipe.recipeKey}}{{(rg.recipes.length > 2 ? '/thumbnails/' : '/')}}{{recipe.titleImage}}"
                                                [alt]="recipe.title" class="w-full border-round img-link" />
                                            @if (recipe?.video) {
                                            <div
                                                class="absolute video-icon border-1 border-pink-600 border-circle w-2rem h-2rem bg-white flex align-items-center justify-content-center">
                                                <i class="pi pi-youtube red"></i>
                                            </div>
                                            }
                                            @if (recipe?.isFavorite) {
                                            <div
                                                class="absolute favorite-icon border-1 border-yellow-500 border-circle w-2rem h-2rem bg-white flex align-items-center justify-content-center">
                                                <i class="pi pi-heart yellow "></i>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="mb-3 font-medium one-line-text">
                                        {{ recipe.title }}
                                    </div>
                                </div>
                            </a>
                        </ng-template>
                    </p-carousel>
                </div>
            </div>
    
            @if(idx === 0 || ( idx % 3 === 0)){
                <div class="col-12 mb-3 mt-3">
                    <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
                        data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
                </div>
            }
        }
    
        <app-recipes-grid [recipes]="recipesList" [groupKey]="groupKey" [groupSubKey]="groupSubKey"></app-recipes-grid>
    
    </div>

    @if(info){
        <div class="col-12">
            <h2>{{uiData?.additionalInfo}}</h2>
            <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(info)"></div>
    
            <ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article"
                data-ad-format="fluid" data-ad-client="ca-pub-9757811370682426" data-ad-slot="3506052310"></ins>
        </div>        
    }
    
</div>