import { inject, Injectable, RendererFactory2 } from '@angular/core';
import { environment } from '../../environments/environment';
import { RecipeCatalogModel, RecipeDataModel, UnitModel } from '../models';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { url } from 'inspector';

export class SeoOptions {
  prefix?: string;
  suffix?: string;
  title!: string;
  description!: string;
  image?: string;
  isArticle?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private titleService = inject(Title);
  private metaService = inject(Meta);
  private document = inject(DOCUMENT);
  private rendererFactory = inject(RendererFactory2);

  getRecipeAddress(document: Document, recipeKey: string): string {
    return `${document.defaultView?.location?.host}/recipe-detail/${recipeKey}/`;
  }

  get language() {
    return environment.language;
  }

  public isMetaRoute() {
    try {
      const url = window?.location.href.split('/').pop();
      return url === 'cookie-terms' || url === 'privacy-policy' || url === 'terms-of-use';
    }
    catch { }

    return false;
  }

  isBraveBrowser() {
    try {
      return ((window.navigator as any)?.brave || undefined) !== undefined;
    } catch {
      return false;
    }
  }

  shuffle(arrayInput: any): any {
    let array = [...arrayInput];
    let currentIndex = array.length;

    while (currentIndex != 0) {
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
  }

  pushAdds(document: Document) {
    setTimeout(() => {
      try {
        //this is number of ads on the page
        const nrOfAds = document.querySelectorAll('.adsbygoogle').length - 1;
        (window as any).adsbygoogle = (window as any)?.adsbygoogle || [];
        [...Array(nrOfAds)].forEach(e => {
          (window as any).adsbygoogle.push({});
        });
      } catch (e) { }
    }, 900);
  }


  public getRecipeDescriptionWithoutLinks(recipe: RecipeDataModel) {
    return recipe ? this.removeHtmlTags(recipe.details.descriptions.join(' ')) : '';
  }


  public removeHtmlTags(value: string) {
    var regex = /(<([^>]+)>)/ig;
    return value.replace(regex, "");
  }

  get breadcrumbStyle() {
    return { style: { 'text-transform': 'capitalize' } }
  }

  getRecipeDetailRoute(recipeKey: string, category: string | undefined = undefined, subCategories: string | undefined = undefined) {
    return `/recipe-detail/${(category ? category + '/' : "")}${(subCategories ? subCategories + '/' : "")}${recipeKey}/`;
  }

  carouselResponsiveOptions = [
    { breakpoint: '1400px', numVisible: 3, numScroll: 3 },
    { breakpoint: '1220px', numVisible: 2, numScroll: 2 },
    { breakpoint: '1100px', numVisible: 1, numScroll: 1 }
  ];

  galleriaResponsiveOptions: any[] = [
    { breakpoint: '1024px', numVisible: 5 },
    { breakpoint: '768px', numVisible: 3 },
    { breakpoint: '560px', numVisible: 1 }
  ];

  dialogBreakpoints = { '1199px': '75vw', '575px': '90vw' }
  dialogStyle = { width: '90vw', height: '90vh' }

  private _isMobile?: boolean = undefined;
  public get IsMobile() {

    if (this._isMobile === undefined) {
      try {
        const gfbmStorage = localStorage?.getItem('gfbm');
        const gfbmWindow = (window as any)?.gfbm;
        if (gfbmStorage || gfbmWindow) {
          this._isMobile = true;
        }
        else {
          this._isMobile = false;
        }
      }
      catch {
        this._isMobile = false;
      }
    }

    return this._isMobile;
  }

  getUnits(units: Array<UnitModel>) {
    return units.map(e => `${e.value ? e.value + ' ' : ''}${e.fractionValue ? e.fractionValue + ' ' : ''}${e.label || ''}`).join(' ')
  }

  videoRoute(meta: RecipeCatalogModel | undefined, groupKey: string | undefined, groupSubKey: string | undefined) {
    if (!meta) {
      return '/';
    }
    return `/video-recipe/${groupKey ? groupKey + '/' : ''}${groupSubKey ? groupSubKey + '/' : ''}${meta.recipeKey}/?video=${meta.video}`;
  }

  public shareRecipe(meta: RecipeCatalogModel | undefined) {
    if (!meta) {
      return;
    }

    navigator.share({
      url: window.location.href,
      title: meta.title,
      text: meta.shortDescription
    });
  }

  get breadcrumbHome() {
    return { icon: 'pi pi-home', url: '/home/', target: '_self' };
  }

  get hrefTarget() {
    return this.IsMobile ? '_self' : '_blank'
  }

  setPageSeoMeta(seoOptions: SeoOptions) {
    this.titleService.setTitle(`${seoOptions.prefix ? seoOptions.prefix + ' ' : ''} ${seoOptions.title.substring(0, 60)} ${seoOptions.suffix ? ' ' + seoOptions.suffix : ''}`);
    this.setMetaDescription(seoOptions.description)
    this.setMetaKeywords(seoOptions.title, seoOptions.description)
    this.setCanonicalLink();
    this.setJsonLd(seoOptions.title, seoOptions.description);
    this.setMetaOpenGraph(seoOptions);
    this.setMetaTwitter(seoOptions);
  }

  setMetaTwitter(seoOptions: SeoOptions) {
    this.metaService.removeTag('name="twitter:title"')
    this.metaService.removeTag('name="twitter:description"')
    this.metaService.removeTag('name="twitter:image"')
    this.metaService.removeTag('name="twitter:card"')

    let tags = [
      { name: 'twitter:title', content: this.removeHtmlTags(seoOptions.title) },
      { name: 'twitter:description', content: this.removeHtmlTags(seoOptions.description) },
    ]

    if (seoOptions.image) {
      tags.push({ name: 'twitter:card', content: seoOptions.image })
      tags.push({ name: 'twitter:image', content: seoOptions.image })
    }

    this.metaService.addTags(tags);
  }

  setMetaOpenGraph(seoOptions: SeoOptions) {
    this.metaService.removeTag('property="og:title"')
    this.metaService.removeTag('property="og:description"')
    this.metaService.removeTag('property="og:image"')
    this.metaService.removeTag('property="og:url"')
    this.metaService.removeTag('property="og:type"')

    let tags = [
      { property: 'og:title', content: this.removeHtmlTags(seoOptions.title) },
      { property: 'og:description', content: this.removeHtmlTags(seoOptions.description) },
      { property: 'og:url', content: this.getCurrentUrl() }
    ]

    if (seoOptions.image) {
      tags.push({ property: 'og:image', content: seoOptions.image })
    }

    if (seoOptions.isArticle) {
      tags.push({ property: 'og:type', content: "article" })
    }

    this.metaService.addTags(tags);
  }

  public setJsonLd(title: string, description: string): void {
    let renderer2 = this.rendererFactory.createRenderer(null, null);
    const data = {
      "@context": "http://schema.org",
      "@type": "Organization",
      "name": "ccargcc",
      "url": this.getCurrentUrl(),
      "headline": `${title} ${description}`,
      "logo": `${this.document.defaultView?.location?.origin}/assets/images/logo/favicon.svg`,
      "datePublished": this.getShortDate(),
    }

    let script = renderer2.createElement('script');
    script.type = 'application/ld+json';
    script.text = `${JSON.stringify(data)}`;

    renderer2.appendChild(this.document.body, script);
  }

  setCanonicalLink() {
    let link = this.document.querySelector("link[rel='canonical']") as HTMLLinkElement;

    if (!link) {
      link = this.document.createElement('link');
    }

    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', this.getCurrentUrl());
    this.document.head.appendChild(link);
  }

  getCurrentUrl() {
    return (this.document.defaultView?.location?.href || '')
      .toLowerCase()
      .replaceAll('//', '/')
      .replace('https:/', 'https://')
      .replace('http:/', 'https://')
      .replace('www.', '');
  }

  setMetaDescription(description: string) {
    this.metaService.removeTag('name="description"')
    this.metaService.addTags(
      [{
        name: 'description',
        content: this.removeHtmlTags(description).substring(0, 160)
      }]
    );
  }

  setMetaKeywords(title: string, description: string) {
    let keywords = `${title} ${description}`
      .replaceAll('.com', ' ')
      .replace(/[^0-9a-z]/gi, ' ')
      .split(' ')
      .filter(e => e.trim() !== '')

    this.metaService.removeTag('name="keywords"')
    this.metaService.addTags(
      [{
        name: 'keywords',
        content: [... new Set(keywords)].join(', ').substring(0, 255)
      }]
    );
  }

  getShortDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm: any = today.getMonth() + 1; // Months start at 0!
    let dd: any = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = `${yyyy}-${mm}-${dd}`;
    return formattedToday;
  }

  get IsRuntime() {
    return (this.document as any)['gfbRunTime'];
  }

  CheckIfLoadCompleted(getTargetCallback: Function, getSkeletonCallback: Function, getContentCallback: Function, contentClass: string, callbackCheck: Function) {

    let targetElementId = getTargetCallback();
    let skeletonId = getSkeletonCallback();
    let contentId = getContentCallback();

    // console.log('CheckIfLoadCompleted', targetElementId, skeletonId, contentId)

    setTimeout(() => {
      if (!targetElementId || !skeletonId || !contentId) {
        this.CheckIfLoadCompleted(getTargetCallback, getSkeletonCallback, getContentCallback, contentClass, callbackCheck)
      }

      if (!this.IsRuntime) {
        this.SwapSkeletonWithContent(skeletonId, contentId, contentClass);
        return;
      }

      let targetElement = this.document.getElementById(targetElementId);
      if (callbackCheck(targetElement)) {
        this.SwapSkeletonWithContent(skeletonId, contentId, contentClass);
      }
      else {
        this.CheckIfLoadCompleted(getTargetCallback, getSkeletonCallback, getContentCallback, contentClass, callbackCheck);
      }
    }, 100)
  }

  SwapSkeletonWithContent(skeletonId: string, contentId: string, contentClass: string) {
    const recipeSkeleton = this.document.getElementById(skeletonId);
    if (recipeSkeleton) {
      recipeSkeleton.setAttribute("class", "col-12 hidden");
    }
    const recipeContent = this.document.getElementById(contentId);
    if (recipeContent) {
      recipeContent.setAttribute("class", contentClass);
    }
  }
}
