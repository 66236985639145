import { Component, HostListener, inject, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { JsonFilesDataService, UtilsService } from '../../services';
import { PaginatorModule } from 'primeng/paginator';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { CardModule } from 'primeng/card';
import { RecipesGridComponent } from '../../components';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-recipes',
  standalone: true,
  imports: [ BreadcrumbModule, CarouselModule, ButtonModule, CardModule, PaginatorModule, RecipesGridComponent, SkeletonModule ],
  templateUrl: './recipes.component.html'
})
export class RecipesComponent { 
  jsonFilesDataService = inject(JsonFilesDataService);
  document = inject(DOCUMENT);
  utilsService = inject(UtilsService);
  sanitizer = inject(DomSanitizer);
  
  uiData: any;
  info: any;
  recipesList: any;
  carouselRecipes: any;
  imageLastIndex = 0;
  breadcrumbItems: MenuItem[] | undefined;

  @Input()  groupKey!: string;
  @Input() groupSubKey!: string;

  @HostListener('window:load')
  onLoad() {
    console.log('recipes onLoad...')
    this.utilsService.CheckIfLoadCompleted(
      () => {
        console.log('recipes index:', this.imageLastIndex)
        if (this.imageLastIndex > 0){
          return 'recipesListImage' + (this.imageLastIndex - 1);
        }
        return null;
      }, 
      () => 'recipeListSkeleton', 
      () => 'recipeListContent', 
      'col-12', 
      (e:any) => e != null
    );
  }

  ngOnInit() {    
    this.jsonFilesDataService.getRecipesList((recipes: any) => {
      recipes = recipes.reverse();
      this.jsonFilesDataService.getRecipesCategoriesUiData((home: any) => {
        this.uiData = home;
        this.setBreadcrumbItems();

        if(this.isSubCategoryView){
          this.recipesList = recipes;

          if(this.groupKey){
            this.recipesList = this.recipesList.filter((e:any) => e.filters.category.find((c:any) => c === this.groupKey))
          }
          if(this.groupSubKey){
            this.recipesList = this.recipesList.filter((e:any) => e.filters.subCategory.find((c:any) => c === this.groupSubKey));      
          }          
        }
        else if(this.isCategoryView){
          const categoryRecipes = recipes.filter((e:any) => e.filters.category.find((c:any) => c === this.groupKey));
          const subCategories = [...new Set(categoryRecipes.flatMap((e:any) => e.filters.subCategory))].sort();
          this.carouselRecipes = [];
          subCategories.forEach(scName => {
            let list = categoryRecipes.filter((cr:any) => cr.filters.subCategory.find((sc:any) => sc === scName))
            list = list.slice(0, Math.min(6, list.length))
            this.carouselRecipes.push({label: scName, recipes: list});
          });              
        }
        else{
          this.recipesList = recipes;
        }

        if(this.recipesList?.length > 0){
          this.recipesList = this.recipesList.map((e:any) => ({...e, index: this.imageLastIndex++}))
        }

        this.carouselRecipes?.forEach(
          (rl:any)=>rl.recipes.forEach((r:any) => {
            r['index'] = this.imageLastIndex++;
          })
        );

        this.info = this.getInfo()

        this.utilsService.setPageSeoMeta({
          title: `${this.uiData.title}${(this.groupKey ? ' ' + this.groupKey + ' ' : '')}${(this.groupSubKey ? ' / ' + this.groupSubKey : '')}`, 
          description: `${this.uiData.title}${(this.groupKey ? ' ' + this.groupKey + ' ' : '')}${(this.groupSubKey ? ' / ' + this.groupSubKey : '')}`,
        });
      })});
  }

  get isSubCategoryView(){
    return this.groupKey && this.groupSubKey;
  }

  get isCategoryView(){
    return this.groupKey && !this.groupSubKey;
  }

  setBreadcrumbItems(){
    this.breadcrumbItems = [      
      { label: this.uiData.breadcrumbRootLabel, url: this.uiData.breadcrumbRootLink, target: '_self' },
    ];

    if(this.isSubCategoryView){
      this.breadcrumbItems.push(
        { label: this.groupKey, url: `${this.uiData.breadcrumbRootLink}${this.groupKey}/`, target: '_self', ...this.utilsService.breadcrumbStyle }
      );

      this.breadcrumbItems.push(
        { label: this.groupSubKey, url: `${this.uiData.breadcrumbRootLink}${this.groupKey}/${this.groupSubKey}/`, target: '_self', ...this.utilsService.breadcrumbStyle }
      );
    }
    else{
      this.breadcrumbItems.push(
        { label: this.groupKey || 'all', url: this.groupKey ? `${this.uiData.breadcrumbRootLink}${this.groupKey}/` : this.uiData.breadcrumbRootLink, target: '_self', ...this.utilsService.breadcrumbStyle }
      );
    }
  }

  getInfo() {
    const key = this.groupSubKey ? `${this.groupKey.toLowerCase()}-${this.groupSubKey.toLowerCase()}` : this.groupKey;
    return this.uiData.info.find((e:any) => e.key === key)?.value ?? undefined;
  }
}
