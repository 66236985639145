import { Component, HostListener, inject, input } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { FieldsetModule } from 'primeng/fieldset';
import { JsonFilesDataService, RecipesService, UtilsService } from '../../services';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-recommended-recipes',
  standalone: true,
  imports: [FieldsetModule, AvatarModule, SkeletonModule ],
  templateUrl: './recommended-recipes.component.html'
})
export class RecommendedRecipesComponent {
  jsonFilesDataService = inject(JsonFilesDataService);
  recipesService = inject(RecipesService);
  utilsService = inject(UtilsService);

  uiData: any;
  recipesList: any;
  groupType = input<string>(); 

  @HostListener('window:load')
  onLoad() {
    this.utilsService.CheckIfLoadCompleted(
      () => (this.recipesList?.length || 0) > 0 
          ? this.uiData?.title + 'Image' + (this.recipesList?.length -1)
          : null
      , 
      () => this.uiData?.title ? this.uiData?.title + 'Skeleton' : null, 
      () => this.uiData?.title ? this.uiData?.title + 'Content' : null, 
      'grid mt-1', 
      (e:any) => e != null
    );
  }

  ngOnInit(){
    this.jsonFilesDataService.getRecommendedUiData((data: any) => {
      this.jsonFilesDataService.getRecipesList((recipes: any) => {
        recipes = recipes.reverse();

        if(this.groupType() === 'top'){
          this.uiData = data.types.find((e:any) => e.key === this.groupType())
          
          let list = recipes.filter((r:any) => r.isFavorite);
          this.recipesList = this.utilsService
            .shuffle(list) 
            .slice(0, Math.min(4, list.length))
        }
        else if(this.groupType() === 'season'){
          const monthNr = (new Date()).getMonth() + 1;
          this.uiData = data.types
            .find((e:any) => e.key === this.groupType())
            .seasons
            .find((e: any) => e.monthNr === monthNr);

          let list = recipes.filter((r:any) => r.seasonMonths?.find((s:any) => s === monthNr));  
          this.recipesList = this.utilsService
            .shuffle(list) 
            .slice(0, Math.min(4, list.length))
        }  
      });
    }) 
  }
}