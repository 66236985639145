<div class="grid">
    <div class="col-2"></div>
    <article class="col-8 grid">
        <header class="col-12 flex justify-content-between align-items-center">
            <div>
                <h1>{{recipe?.meta?.title}}</h1>
                <p class="text-500">{{uiData?.siteAddress}}</p>
            </div>
            
            <p-button label="{{uiData?.printRecipe}}" (onClick)="print()" />
        </header>

        <div class="col-12 flex justify-content-center p-3">
            <figure class="relative text-center">
                <img [alt]="recipe?.meta?.title"
                    src="/assets/recipes/{{utilsService.language}}/{{recipeKey}}/{{recipe?.details?.titleImage}}"
                    loading="lazy"
                    class="max-w-30rem">
                <figcaption class="text-2xl pt-3 pb-3 text-left"
                    [innerHTML]="sanitizer.bypassSecurityTrustHtml(utilsService.getRecipeDescriptionWithoutLinks(recipe))"></figcaption>
                @if(recipe?.details?.descriptionNote){
                    <mark class="recipe-detail-mark">{{recipe?.details?.descriptionNote}}</mark>
                }                
            </figure>
        </div>

        <section class="col-12">
            <header><h2>{{uiData?.ingredients}}</h2></header>                
            <div class="card w-full p-3">
                <p-table [value]="recipe?.details?.ingredients || []" styleClass="p-datatable-sm">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="w-4">{{uiData?.name}}</th>
                            <th class="w-2">{{recipe?.details?.ingredientsWithSingleUnits ? 'Units' : 'Metric'}}</th>
                            @if(!recipe?.details?.ingredientsWithSingleUnits){
                                <th class="w-2">{{uiData?.us}}</th>
                            }     
                            @if(recipeHasAnyNotes(recipe?.details?.ingredients)){
                                <th class="w-4 text-center">{{uiData?.tip}}</th>
                            }                         
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-ingredient>
                        <tr>
                            <td>
                                @if(!ingredient?.linkRoute){
                                    <span>{{ingredient.name}}</span>
                                }
                                @if(ingredient?.linkRoute){
                                    <a [href]="ingredient?.linkRoute" target="{{this.utilsService.hrefTarget}}" onclick="event.stopPropagation()">
                                        <u>{{ingredient.name}}</u>
                                    </a>    
                                }                                
                            </td>
                            <td>
                                <span>{{utilsService.getUnits(ingredient.metricUnits)}}</span>
                            </td>
                            @if(!recipe?.details?.ingredientsWithSingleUnits){
                                <td>
                                    <span>{{utilsService.getUnits(ingredient.usUnits)}}</span>
                                </td>
                            }       
                            @if(recipeHasAnyNotes(recipe?.details?.ingredients)){
                                <td>
                                    @if(ingredient.notes.length > 0){
                                        <td>
                                            @for (note of ingredient.notes; track $index) {
                                                <div>{{note}}</div>
                                            }
                                        </td>
                                    }                                
                                </td>
                            }                        
                        </tr>
                    </ng-template>
                </p-table>    
            </div>   
        </section>

        @for (additionalIngredients of recipe?.details?.additionalIngredients || []; track $index) {
            <section class="col-12">
                <header><h2>{{additionalIngredients?.title || uiData?.additionalIngredients}}</h2></header>                
                <div class="card w-full p-3">
                    <p-table [value]="additionalIngredients.ingredients" styleClass="p-datatable-sm">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="w-4">{{uiData?.name}}</th>
                                <th class="w-2">{{recipe?.details?.ingredientsWithSingleUnits ? 'Units' : 'Metric'}}</th>
                                @if(!recipe?.details?.ingredientsWithSingleUnits){
                                    <th class="w-2">{{uiData?.us}}</th>
                                }  
                                @if(recipeHasAnyNotes(additionalIngredients.ingredients)){
                                    <th class="w-4 text-center">{{uiData?.tip}}</th>
                                }                            
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-ingredient>
                            <tr>
                                <td>
                                    @if(!ingredient?.linkRoute){
                                        <span>{{ingredient.name}}</span>
                                    }
                                    @if(ingredient?.linkRoute){
                                        <a [href]="ingredient?.linkRoute" target="{{this.utilsService.hrefTarget}}" onclick="event.stopPropagation()">
                                            <u>{{ingredient.name}}</u>
                                        </a>    
                                    }                                
                                </td>
                                <td>
                                    <span>{{utilsService.getUnits(ingredient.metricUnits)}}</span>
                                </td>
                                @if(!recipe?.details?.ingredientsWithSingleUnits){
                                    <td>
                                        <span>{{utilsService.getUnits(ingredient.usUnits)}}</span>
                                    </td>
                                }                            
                                @if(recipeHasAnyNotes(additionalIngredients.ingredients)){
                                    <td>
                                        @if(ingredient.notes.length > 0){
                                            <td>
                                                @for (note of ingredient.notes; track $index) {
                                                    <div>{{note}}</div>
                                                }
                                            </td>
                                        }                                
                                    </td>
                                }                                
                            </tr>
                        </ng-template>
                    </p-table>    
                </div>   
            </section>
        }

        @if(recipe?.details?.optionalIngredients?.length){
            <section class="col-12">
                <header>
                    <h2>{{uiData?.optionalIngredients}} {{(recipe.details.optionalIngredientsSuffix || '') !== '' ? ' ' + recipe.details.optionalIngredientsSuffix : ''}}:</h2>    
                </header>                
                <div class="card w-full p-3">
                    <p-table [value]="recipe?.details?.optionalIngredients || []" selectionMode="single" dataKey="name"
                        tableStyleClass="w-full" styleClass="p-datatable-sm">
                        <ng-template pTemplate="body" let-optionalIngredient>
                            <tr>
                                <td class="w-1rem"><span class="pi pi-circle-fill green"></span></td>
                                <td>
                                    <span>{{optionalIngredient}}</span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>    
                </div>   
            </section>        
        }

        @for (moreOptional of recipe?.details?.moreOptionalIngredients; track $index) {
            <section class="col-12">
                <header><h2>{{moreOptional.title}}:</h2></header>                
                <div class="card w-full p-3">
                    <p-table [value]="moreOptional.ingredients" selectionMode="single" dataKey="name"
                        tableStyleClass="w-full" styleClass="p-datatable-sm">
                        <ng-template pTemplate="body" let-optionalIngredient>
                            <tr>
                                <td class="w-1rem"><span class="pi pi-circle-fill green"></span></td>
                                <td><span>{{optionalIngredient}}</span></td>
                            </tr>
                        </ng-template>
                    </p-table>    
                </div>   
            </section>                        
        }

        @if(recipe?.details?.equipments?.length){
            <section class="col-12">
                <header><h2>{{uiData?.equipment}}</h2></header>                
                <div class="card w-full p-3">
                    <p-table [value]="recipe?.details?.equipments || []" selectionMode="single" dataKey="name"
                        tableStyleClass="w-full" styleClass="p-datatable-sm">
                        <ng-template pTemplate="body" let-equipment>
                            <tr>
                                <td class="w-1rem"><span class="pi pi-circle-fill green"></span></td>
                                <td><span>{{equipment}}</span></td>
                            </tr>
                        </ng-template>
                    </p-table>    
                </div>   
            </section>        
        }        

        <section class="col-12">                                                
            <div class="card w-full p-3">
                <div class="card">
                    <p-dataView [value]="recipe?.details?.steps">
                        <ng-template pTemplate="header">
                            <header class="flex justify-content-between align-items-center" >
                                <h2>{{uiData?.steps}}</h2>
                            </header>
                        </ng-template>                            
                        <ng-template pTemplate="list" let-steps>
                            <div class="col-12" class="col-12">
                                <ol>                                                                    
                                    @for (step of steps; track $index; let first = $first; let i = $index;) {
                                        <li><div class="font-medium text-secondary text-lg m-3" [innerHTML]="sanitizer.bypassSecurityTrustHtml(step.desc)"></div></li>
                                    }
                                </ol>                                
                            </div>
                        </ng-template>
                    </p-dataView>
                </div>
            </div>   
        </section>    

        <footer>
            <p class="text-500">{{recipeAddress}}</p>
            <p class="text-500">{{printDate()}}</p>
        </footer>
    </article>  
    <div class="col-2"></div>   
</div>

