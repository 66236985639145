import { Component, inject } from '@angular/core';
import { JsonFilesDataService, LoadingService, RecipesService } from '../../services';
import { BadgeModule } from 'primeng/badge';
import { AvatarModule } from 'primeng/avatar';
import { CommonModule, DOCUMENT } from '@angular/common';
import { MenubarModule } from 'primeng/menubar';
import { ProgressBarModule } from 'primeng/progressbar';
import { ButtonModule } from 'primeng/button';
import { MenuNode } from '../../models';
import { FilterComponent } from '../filter/filter.component';
import { SearchComponent } from '../search/search.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, MenubarModule, BadgeModule, AvatarModule, ProgressBarModule, ButtonModule, FilterComponent, SearchComponent],
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  loadingService = inject(LoadingService);
  jsonFilesDataService = inject(JsonFilesDataService);
  recipesService = inject(RecipesService);
  document = inject(DOCUMENT);

  uiData: any;
  recipesCategories: any;
  currentPathname!: string;
  showFilterDialog: boolean = false;
  showSearchDialog: boolean = false;

  ngOnInit() {
    this.currentPathname = (this.document.defaultView?.location?.pathname || "").toLowerCase().replace('/recipes/', '');

    this.jsonFilesDataService
      .getHeaderUiData((header: any) => {
        this.uiData = header
        this.recipesService
          .getCategories(header.allMenuItemName, header.categoriesMeta, (categories: any) => {
            this.uiData.categoriesMeta

            this.recipesCategories = [...categories, ...header.items];
          });
      });
  }

  linkClass(menuNode: MenuNode) {
    if (menuNode.isRoot) {
      if (menuNode.href && this.currentPathname.indexOf(menuNode.href) === 0) {
          return 'flex align-items-center p-menuitem-link text-yellow-800 active-link'
      }
      else if(this.currentPathname.indexOf(menuNode.label) === 0) {
        return 'flex align-items-center p-menuitem-link text-yellow-800 active-link'
      }
    }
    return 'flex align-items-center p-menuitem-link';
  }
}