import { Component, HostListener, inject } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { JsonFilesDataService, UtilsService } from '../../services';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [AvatarModule, ButtonModule, SkeletonModule],
  templateUrl: './about.component.html'
})
export class AboutComponent {
  jsonFilesDataService = inject(JsonFilesDataService);
  utilsService = inject(UtilsService);
  document = inject(DOCUMENT);

  uiData: any;

  @HostListener('window:load')
  onLoad() {
    this.utilsService.CheckIfLoadCompleted(
      () => 'aboutContent', 
      () => 'aboutSkeleton', 
      () => 'aboutContent', 
      'about-text p-2', 
      (e:any) => (e?.innerText.length || 0) > 0);
  }

  ngOnInit(){
    this.jsonFilesDataService.getAboutUiData((data: any) => {
      this.uiData = data;
    }) 
  }
}
