import { Component, inject, Input } from '@angular/core';
import { JsonFilesDataService, RecipesService, UtilsService } from '../../services';
import { FlourDataModel, RecipeCatalogModel } from '../../models';
import { TableModule } from 'primeng/table';
import { RecipesGridComponent } from '../../components';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-flour-comparer',
  standalone: true,
  imports: [TableModule, RecipesGridComponent],
  templateUrl: './flour-comparer.component.html'
})
export class FlourComparerComponent {
  jsonFilesDataService = inject(JsonFilesDataService);
  recipesService = inject(RecipesService);
  utilsService = inject(UtilsService);
  sanitizer = inject(DomSanitizer);
  
  @Input() flourKey!: string;

  uiData: any;
  recipesList: any = [];
  selectedFlour!: FlourDataModel;
  compareFlours!: Array<FlourDataModel>;
  flours!: Array<FlourDataModel>;

  ngOnInit() {
      this.jsonFilesDataService.getFloursUiData((uiData: any) => {
        this.uiData = uiData;
        
        this.selectedFlour = this.uiData.flourData
          .find((e:FlourDataModel) => e.key === this.flourKey) || this.uiData.flourData[0];
        
        this.compareFlours = this.uiData.flourData
          .filter((e:FlourDataModel) => e.key !== this.selectedFlour.key)
          .sort((a:FlourDataModel, b:FlourDataModel) => a.flour.localeCompare(b.flour));        

        this.flours = [this.selectedFlour, ...this.compareFlours];  

        if(this.selectedFlour.flour){
          this.jsonFilesDataService.getRecipesList((recipes: any) => {
            this.recipesList = (recipes || []).filter((e:RecipeCatalogModel) => 
              e.filters.flour.find(f => 
                f.toLowerCase().indexOf(this.selectedFlour.flour.toLowerCase()) !== -1 
                || this.selectedFlour.flour.toLowerCase().indexOf(f.toLowerCase()) !== -1 
              )      
            )        
          });
        }
        
        this.utilsService.setPageSeoMeta({
          title: `${this.uiData.header} ${this.selectedFlour.flour}`, 
          description: this.selectedFlour.description
        });
    });
  } 
  
  setComparerClass(current: string, target: string): any{
    if(current === '' || target === '' || +current === +target){
      return {
        'no-icon': true
      };
    }
    else if(+current > +target){
      return {
        'pi': true,
        'pi-arrow-up': true,
        'up-icon': true
      };
    }
    else if(+current < +target){
      return {
        'pi': true,
        'pi-arrow-down': true,
        'down-icon': true
      };
    }
  }

  setTextComparerClass(current: string, target: string): any{
    if(current === '' || target === '' || +current === +target){
      return {
        'same-value': true
      };
    }    
  }
}