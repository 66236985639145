import { Routes } from '@angular/router';
import { CookieTermsComponent, FlourComparerComponent, HomeComponent, 
    IndexNowComponent, PrintComponent, PrivacyPolicyComponent, RecipeDetailComponent, 
    RecipesComponent, TermsOfUseComponent, 
    VideoComponent
} from './pages';

export const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'home', component: HomeComponent },
    { path: 'recipes', component: RecipesComponent },
    { path: 'recipes/:groupKey', component: RecipesComponent },
    { path: 'recipes/:groupKey/:groupSubKey', component: RecipesComponent },
    { path: 'recipe-detail/:recipeKey', component: RecipeDetailComponent },  
    { path: 'recipe-detail/:groupKey/:recipeKey', component: RecipeDetailComponent },  
    { path: 'recipe-detail/:groupKey/:groupSubKey/:recipeKey', component: RecipeDetailComponent },  
    { path: 'flour-comparer/:flourKey', component: FlourComparerComponent },
    { path: 'flour-comparer', component: FlourComparerComponent },
    { path: 'indexnow', component: IndexNowComponent },
    { path: 'cookie-terms', component: CookieTermsComponent },
    { path: 'terms-of-use', component: TermsOfUseComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'video-recipe/:recipeKey', component: VideoComponent},
    { path: 'video-recipe/:groupKey/:recipeKey', component: VideoComponent },  
    { path: 'video-recipe/:groupKey/:groupSubKey/:recipeKey', component: VideoComponent },  
    { path: 'print/:recipeKey', component: PrintComponent},
    { path: '**', component: HomeComponent },
];
