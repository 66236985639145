import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AsideComponent, DockComponent, FooterComponent } from './components';
import { HeaderComponent } from "./components/header/header.component";
import { UtilsService } from './services';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, FooterComponent, HeaderComponent, DockComponent, AsideComponent],
  providers: [ConfirmationService, MessageService],
  templateUrl: './app.component.html'
})
export class AppComponent {
  utilsService = inject(UtilsService);
  document = inject(DOCUMENT);
  
  isPrintMode: boolean = false;

  ngOnInit(){    
    this.isPrintMode = this.document.defaultView?.location.href.indexOf('/print/') !== -1;    
  }

  ngAfterViewInit() {
    this.utilsService.pushAdds(this.document);
  }  
}
