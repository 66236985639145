import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  getTemporaryItem<T>(key: string) {
    try {
      let result = JSON.parse(localStorage.getItem(key) || 'null');

      if (result) {
        if (result.expireTime <= Date.now()) {
          localStorage.removeItem(key);
          return null;
        }

        return result.data as T;
      }
    }catch { }
    return null;
  }

  setTemporaryItem(key: string, value: any, maxAgeHours: number = 1) {
    try{
      const maxAge = maxAgeHours * 60 * 60 * 1000;
      let result = {
        data: value,
        expireTime: Date.now() + maxAge
      }
  
      localStorage.setItem(key, JSON.stringify(result));
    }catch{}
  }

  public setPermanentItem(key: string, value: any) {
    try{
      localStorage.setItem(key, JSON.stringify(value));
    }
    catch{}
  }

  public getPermanentItem<T>(key: string) {
    try {
      let result = JSON.parse(localStorage.getItem(key) || 'null');
      if (result){
        return result as T;
      }      
    }
    catch {}
    return null;
  }

  removeItem(key: string) {
    try {
      localStorage.removeItem(key);  
    } catch {}    
  }

  public get isMobile(){
    return localStorage?.getItem('gfbm');
  }  
}
