import { Filter } from "./Filter";

export class TemporaryStateModel {
    public static key: string = 'gfb-temp';

    constructor(        
        public showFilter: boolean = false,
        public filters: Filter[] = [],
        public lastSearchBy: string = '',
        public showSearch: boolean = false
    ) { }
}
