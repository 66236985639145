import { Component, inject, Inject } from '@angular/core';
import { RecommendedRecipesComponent } from '../recommended-recipes/recommended-recipes.component';
import { AboutComponent } from '../about/about.component';
import { DOCUMENT } from '@angular/common';
import { UtilsService } from '../../services';

@Component({
  selector: 'app-aside',
  standalone: true,
  imports: [AboutComponent, RecommendedRecipesComponent],
  templateUrl: './aside.component.html',
})
export class AsideComponent {
  document = inject(DOCUMENT);
  utilsService = inject(UtilsService);
  
  ngAfterViewInit() {
    setTimeout(() => {
      const maxHeight = this.document.documentElement.scrollHeight;
      let adds = this.document.getElementsByName("add");
      let currentHeight = 1000; 
      for (let index = 0; index < adds.length; index++) {
        if(maxHeight > currentHeight){
          adds[index].style.display = "block"
        }
        currentHeight += 1000;          
      }

      this.utilsService.pushAdds(this.document);
    }, 1000)
  }
}
